import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import theme from 'config/theme';

interface ConfirmationData {
  title?: string;
  body?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

interface FullscreenConfirmationProps {
  confirmationData: ConfirmationData;
}

export const FullscreenConfirmation: React.FC<FullscreenConfirmationProps> = ({ confirmationData }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: '#00000099',
        zIndex: 99,
        overflow: 'hidden !important',
      }}>
      <div style={{ marginBottom: theme.spacing(4), display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        <Box p={3} sx={{ backgroundColor: 'white', borderRadius: 5 }}>
          <Typography variant="h2" style={{ marginTop: 10 }}>
            {confirmationData?.title}
          </Typography>
          <Typography variant="body1" style={{ marginTop: 10 }}>
            {confirmationData?.body}
          </Typography>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
            <Button variant="contained" color="error" onClick={confirmationData?.onCancel}>
              {confirmationData?.cancelText}
            </Button>
            <Button variant="contained" color="primary" onClick={confirmationData?.onConfirm}>
              {confirmationData?.confirmText}
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default FullscreenConfirmation;
