import styled from '@emotion/styled';

import theme from 'config/theme';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${theme.spacing(4)};
  column-gap: ${theme.spacing(4)};
  row-gap: ${theme.spacing(4)};
`;
