import { Alert, Box, Button, Card, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Layout } from 'components';
import { useAuth } from 'context';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from 'types/generic';

const Login2FA = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [code, setCode] = useState('');

  const location = useLocation();

  const { login } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the login logic here

    if (code.length !== 6) {
      setError(true);
    }

    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_HUB_API_URL}/auth/twoFactor/login`, {
        code,
        userId: location.state.userId,
      });

      const data = resp.data;

      const userData = resp.data as User;
      login(userData);

      console.info(data);
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout notAuthenticated={true}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Card sx={{ padding: '40px' }}>
            <Typography component="h1" variant="h5">
              Two Factor Challenge
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
              Enter the two factor code sent to your device
            </Typography>

            {error && (
              <Alert onClose={() => setError(false)} severity="error">
                Incorrect token
              </Alert>
            )}
            <Box component="form" sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Code"
                name="code"
                type="number"
                autoFocus
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />

              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                {isLoading ? <CircularProgress size={25} color="secondary" /> : 'LOGIN'}
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Layout>
  );
};

export default Login2FA;
