import { BrandFamily, DesignMarket, Location, Market, ProductGeneration, ProductLine, ProductType, SfpPlatform } from 'api/components/schemas';
import React, { createContext, useEffect, useState } from 'react';
import { getBrandFamilies, getProductGenerations, getProductLines, getProductTypes, getSfpPlatforms } from 'utils/apiRequests/generic';
import { getDesignMarkets, getLocations, getMarkets } from 'utils/apiRequests/locations';

import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';

import { useAuth } from './AuthContext';

interface AppContextProps {
  sfpPlatforms: SfpPlatform[] | null;
  setSfpPlatforms: React.Dispatch<React.SetStateAction<SfpPlatform[] | null>>;
  productGenerations: ProductGeneration[] | null;
  setProductGenerations: React.Dispatch<React.SetStateAction<ProductGeneration[] | null>>;
  productTypes: ProductType[] | null;
  setProductTypes: React.Dispatch<React.SetStateAction<ProductType[] | null>>;
  brandFamilies: BrandFamily[] | null;
  setBrandFamilies: React.Dispatch<React.SetStateAction<BrandFamily[] | null>>;
  productLines: ProductLine[] | null;
  setProductLines: React.Dispatch<React.SetStateAction<ProductLine[] | null>>;
  designMarkets: DesignMarket[] | null;
  setDesignMarkets: React.Dispatch<React.SetStateAction<DesignMarket[] | null>>;
  markets: Market[] | null;
  setMarkets: React.Dispatch<React.SetStateAction<Market[] | null>>;
  locations: Location[] | null;
  setLocations: React.Dispatch<React.SetStateAction<Location[] | null>>;
}

const AppContext = createContext<AppContextProps>({
  sfpPlatforms: null,
  setSfpPlatforms: () => {},
  productGenerations: null,
  setProductGenerations: () => {},
  productTypes: null,
  setProductTypes: () => {},
  brandFamilies: null,
  setBrandFamilies: () => {},
  productLines: null,
  setProductLines: () => {},
  designMarkets: null,
  setDesignMarkets: () => {},
  markets: null,
  setMarkets: () => {},
  locations: null,
  setLocations: () => {},
});

export const AppProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sfpPlatforms, setSfpPlatforms] = useState<SfpPlatform[] | null>(null);
  const [productGenerations, setProductGenerations] = useState<ProductGeneration[] | null>(null);
  const [productTypes, setProductTypes] = useState<ProductType[] | null>(null);
  const [brandFamilies, setBrandFamilies] = useState<BrandFamily[] | null>(null);
  const [productLines, setProductLines] = useState<ProductLine[] | null>(null);
  const [designMarkets, setDesignMarkets] = useState<DesignMarket[] | null>(null);
  const [markets, setMarkets] = useState<Market[] | null>(null);
  const [locations, setLocations] = useState<Location[] | null>(null);

  const { user } = useAuth();

  const getData = async () => {
    setIsLoading(true);

    const sfpPlatformsPromise = getSfpPlatforms();
    const productGenerationsPromise = getProductGenerations();
    const productTypesPromise = getProductTypes();
    const brandFamiliesPromise = getBrandFamilies();
    const productLinesPromise = getProductLines();
    const designMarketsPromise = getDesignMarkets();
    const marketsPromise = getMarkets();
    const locationsPromise = getLocations();

    const [
      sfpPlatformsResp,
      productGenerationsResp,
      productTypesResp,
      brandFamiliesResp,
      productLinesResp,
      designMarketsResp,
      marketsResp,
      locationsResp,
    ] = await Promise.all([
      sfpPlatformsPromise,
      productGenerationsPromise,
      productTypesPromise,
      brandFamiliesPromise,
      productLinesPromise,
      designMarketsPromise,
      marketsPromise,
      locationsPromise,
    ]);

    setSfpPlatforms(sfpPlatformsResp || null);
    setProductGenerations(productGenerationsResp || null);
    setProductTypes(productTypesResp || null);
    setBrandFamilies(brandFamiliesResp || null);
    setProductLines(productLinesResp || null);
    setDesignMarkets(designMarketsResp || null);
    setMarkets(marketsResp || null);
    setLocations(locationsResp || null);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        sfpPlatforms,
        setSfpPlatforms,
        productGenerations,
        setProductGenerations,
        productTypes,
        setProductTypes,
        brandFamilies,
        setBrandFamilies,
        productLines,
        setProductLines,
        designMarkets,
        setDesignMarkets,
        markets,
        setMarkets,
        locations,
        setLocations,
      }}>
      {isLoading ? <LoadingScreen /> : children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};
