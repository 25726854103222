import { CircularProgress } from '@mui/material';
import React from 'react';

export const Loader: React.FC = () => {
  const loaderContainerStyle = {
    display: 'flex',
    justifxwyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  };

  return (
    <div style={loaderContainerStyle}>
      <CircularProgress />
    </div>
  );
};
