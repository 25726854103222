import { LinearProgress, Typography } from '@mui/material';

export const LoadingScreen = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Initialising app...
      </Typography>
      <LinearProgress color="primary" sx={{ width: '60vw' }} />
    </div>
  );
};
