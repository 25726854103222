import { Check } from '@mui/icons-material';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import WarningIcon from '@mui/icons-material/Warning';
import { Checkbox } from '@mui/material';
import { AttributeValue, FlavourInstance, FlavourProduct, FlavourTranslation, SKU } from 'api/components/schemas';
import { AccordionTable, Actions, Layout, Loader, MenuTabs } from 'components';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFlavourInstance, getFlavourLanguages, updateFlavourInstances } from 'utils/apiRequests/flavours';
import { getSkus } from 'utils/apiRequests/skus';

import { AddDeploymentSkuAccordionItem } from 'components/AddDeploymentSkuAccordionItem';

import theme from 'config/theme';

interface TableData {
  id: string;
  name: string;
  launchDate: Date;
  delistDate: Date;
  lastUpdated: Date;
  status: string;
  hidden: boolean;
  product?: FlavourProduct;
  sortIndex: number;
}

const AddDeploymentSKU = () => {
  const [hasEditted, setHasEditted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flavourInstance, setFlavourInstance] = useState<FlavourInstance | null>(null);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [flavourLanguages, setFlavourLanguages] = useState<AttributeValue[]>([]);
  const [skusForFlavourInstances, setSkusForFlavourInstancesResp] = useState<SKU[]>([]);
  const [languagesForFlavourInstances, setLanguagesForFlavourInstancesResp] = useState<AttributeValue[]>([]);

  const { state } = useLocation();

  const navigate = useNavigate();

  const flavourInstanceId = state?.flavourInstanceId as string;

  const [lang, setLang] = useState('en');

  const getLanguagesForFlavourInstances = (flavourInstancesResp: FlavourInstance, flavourLanguagesResp: AttributeValue[]) => {
    return flavourInstancesResp.products
      .map((product) => product.translations.map((translation) => translation.languageCode))
      .flat()
      .map((lan) => {
        const languageValue = flavourLanguagesResp.find((language) => language.value === lan) as AttributeValue;
        // we do this as languageValue has an id field which we don't need
        return { label: languageValue.label, value: languageValue.value };
      });
  };

  const getData = async () => {
    setIsLoading(true);
    const flavourInstanceResp = await getFlavourInstance(flavourInstanceId);
    const skusResp = await getSkus();
    const flavourLanguagesResp = await getFlavourLanguages();

    if (flavourInstanceResp && skusResp && flavourLanguagesResp) {
      const skusForFlavourInstancesResp = flavourInstanceResp.products.map((product) => {
        const sku = skusResp.find((sku) => sku._id === product.skuId);
        return sku as SKU;
      });

      const languagesForFlavourInstancesResp = getLanguagesForFlavourInstances(flavourInstanceResp, flavourLanguagesResp);

      setLanguagesForFlavourInstancesResp(languagesForFlavourInstancesResp);

      const tableDataResp = formatDataForTable(skusForFlavourInstancesResp, flavourInstanceResp);

      setTableData(tableDataResp);

      setSkusForFlavourInstancesResp(skusForFlavourInstancesResp);

      setFlavourLanguages(flavourLanguagesResp);

      setFlavourInstance(flavourInstanceResp);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!flavourInstanceId) {
      navigate('/digital-tools/flavor-navigator/add-deployment');
    } else {
      getData();
    }
  }, []);

  const formatDataForTable = (skusForFlavourInstancesResp: SKU[], flavourInstance: FlavourInstance) => {
    const formattedData = skusForFlavourInstancesResp.map((sku) => {
      let flavourProduct = flavourInstance?.products.find((product) => product.skuId === sku._id);

      if (flavourInstance?.draft) {
        flavourProduct = flavourInstance?.draft.products.find((product) => product.skuId === sku._id);
      }

      console.log('flavourProduct:', flavourProduct);

      let status = 'published';

      flavourProduct?.translations.forEach((translation) => {
        if (
          !translation.disclaimer &&
          !translation.longDescription &&
          !translation.productName &&
          !translation.shortDescription &&
          !translation.packFrontUrl &&
          !translation.packTopUrl &&
          !translation.packFrontId &&
          !translation.packTopId
        ) {
          status = 'draft';
        }
      });

      return {
        id: sku._id as string,
        name: sku.skuName,
        launchDate: new Date(sku.globalLaunchDate),
        delistDate: new Date(sku.globalDelistDate),
        lastUpdated: new Date(flavourInstance?.lastUpdated ?? 1),
        hidden: flavourInstance?.delisted,
        product: flavourProduct,
        sortIndex: flavourProduct?.sortIndex as number,
        status,
      };
    });
    return formattedData;
  };

  const onLanguagechange = (languageCode: string) => {
    if (!flavourInstance) return;
    // check if language code already in instance
    const languageAlreadyExists = flavourInstance?.products.find((product) =>
      product.translations.find((translation) => translation.languageCode === languageCode),
    );

    if (languageAlreadyExists) {
      setLang(languageCode);
    } else {
      const newInstance = { ...flavourInstance };

      newInstance.products.map((product) => {
        const newProduct = { ...product };
        newProduct.translations.push({
          languageCode: languageCode,
          longDescription: '',
          shortDescription: '',
          productName: '',
          disclaimer: '',
          packFrontId: '',
          packTopId: '',
          packFrontUrl: '',
          packTopUrl: '',
        });
        return newProduct;
      });

      const languagesForFlavourInstancesResp = getLanguagesForFlavourInstances(newInstance, flavourLanguages);

      setLanguagesForFlavourInstancesResp(languagesForFlavourInstancesResp);

      setFlavourInstance(newInstance);
      setHasEditted(true);
      setLang(languageCode);
    }
  };

  const onConfirmNewTranslation = (flavourTranslation: FlavourTranslation, flavourProduct: FlavourProduct) => {
    const skuId = flavourProduct.skuId;

    setHasEditted(true);
    setFlavourInstance((instance) => {
      if (!instance) return instance;

      const newInstance = { ...instance };
      const newProducts = instance.products.map((product) => {
        const newProduct = { ...product };
        if (product.skuId === skuId) {
          const doesLanguageExist = newProduct.translations.find((translation) => translation.languageCode === flavourTranslation.languageCode);
          if (doesLanguageExist) {
            newProduct.translations = newProduct.translations.map((translation) => {
              if (translation.languageCode === flavourTranslation.languageCode) {
                return flavourTranslation;
              } else {
                return translation;
              }
            });
          } else {
            newProduct.translations.push(flavourTranslation);
          }
        }
        return newProduct;
      });
      newInstance.products = newProducts;
      return newInstance;
    });

    enqueueSnackbar("Successfully updated flavour's translations", { variant: 'success' });
  };

  const onPublishClick = async () => {
    setIsLoading(true);
    if (!flavourInstance) return;
    const resp = await updateFlavourInstances(flavourInstance);
    if (resp) {
      enqueueSnackbar('Successfully updated flavour instances', { variant: 'success' });
    } else {
      enqueueSnackbar('Error updating flavour instances', { variant: 'error' });
    }
    setHasEditted(false);
    setIsLoading(false);
  };

  const onSaveDraftClick = async () => {
    setIsLoading(true);
    if (!flavourInstance) return;
    const resp = await updateFlavourInstances(flavourInstance, true);
    if (resp) {
      enqueueSnackbar('Successfully saved new draft', { variant: 'success' });
    } else {
      enqueueSnackbar('Error saving draft', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const onRemoveLanguage = (lang: { label: string; value: string }) => {
    console.log('lang', lang);
    if (lang.value === 'en') {
      enqueueSnackbar('Cannot remove English language', { variant: 'error' });
      return;
    }

    setLang('en');
    setHasEditted(true);
    setLanguagesForFlavourInstancesResp((oldS) => {
      if (!oldS) return oldS;
      return oldS.filter((language) => language.value !== lang.value);
    });
    setFlavourInstance((oldS) => {
      if (!oldS) return oldS;
      const newInstance = { ...oldS };
      newInstance.products = newInstance.products.map((product) => {
        product.translations = product.translations.filter((translation) => translation.languageCode !== lang.value);
        return product;
      });
      return newInstance;
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (hasEditted) {
        // Standard message shown by the browser, custom messages are no longer supported
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasEditted]);

  const onArrowPress = (direction: 'up' | 'down', id: string) => {
    console.log('direction:', direction);
    console.log('id:', id);
    // i want to now change the order of flavourInstances based on wether is was an up or down arrow press and change the sort index
    setFlavourInstance((oldS) => {
      if (!oldS || oldS.products.length < 2) return oldS;

      const products = [...oldS.products]; // Clone the array
      const currentIndex = products.findIndex((p) => p.skuId === id);

      // Calculate new index based on the direction
      let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

      // Boundary checks
      if (newIndex < 0 || newIndex >= products.length) {
        return oldS; // No change needed
      }

      // Adjust sortIndex for the product to be moved
      products[currentIndex].sortIndex = newIndex;

      // Find and adjust the sortIndex of the product currently at the new position
      const affectedProduct = products.find((p) => p.sortIndex === newIndex && p.skuId !== id);
      if (affectedProduct) {
        affectedProduct.sortIndex = currentIndex;
      }

      // Sort products based on updated sortIndex
      products.sort((a, b) => a.sortIndex - b.sortIndex);

      const newTableData = formatDataForTable(skusForFlavourInstances, { ...oldS, products });
      setTableData(newTableData);

      return { ...oldS, products };
    });
    setHasEditted(true);
  };

  console.log('tableData:', tableData);

  return (
    <Layout>
      <Actions
        breadcrumbs={[
          { label: 'Digital Tools', path: '/digital-tools' },
          { label: 'Flavour Navigator', path: '/digital-tools/flavor-navigator' },
          { label: 'Add Deployment', path: '/digital-tools/flavor-navigator/add-deployment' },
          { label: 'SKUs' },
        ]}
        ctas={[
          {
            type: 'button',
            color: 'tertiary',
            variant: 'outlined',
            disabled: !hasEditted,
            label: 'Save draft',
            Icon: SaveIcon,
            onClick: onSaveDraftClick,
          },
          {
            type: 'button',
            disabled: !hasEditted,
            variant: 'contained',
            label: 'Publish',
            Icon: PublishIcon,
            onClick: onPublishClick,
          },
        ]}
      />
      {isLoading ? <Loader /> : <></>}

      <MenuTabs
        value={lang}
        moreLinks={flavourLanguages}
        links={languagesForFlavourInstances}
        canDelete
        onDelete={onRemoveLanguage}
        onChange={(_, newValue) => {
          if (newValue !== 'more') {
            onLanguagechange(newValue);
          }
        }}
      />

      <AccordionTable
        headings={['Name (LAC Facing)', 'Launch Date', 'Delist Date', 'Content last updated', 'Hidden', 'Content status']}
        onArrowPress={onArrowPress}
        accordions={
          tableData
            ? tableData
                ?.sort((a, b) => a.sortIndex - b.sortIndex)
                .map((data) => {
                  const productContent = data.product?.translations.find((translation) => translation.languageCode === lang) as FlavourTranslation;

                  return {
                    content: (
                      <AddDeploymentSkuAccordionItem
                        productContent={productContent}
                        onConfirm={(translation) => onConfirmNewTranslation(translation, data.product as FlavourProduct)}
                      />
                    ),
                    data: [
                      data.name,
                      data.launchDate.toLocaleDateString(),
                      data.delistDate.toLocaleDateString(),
                      `${data.lastUpdated.toLocaleDateString()} at ${data.lastUpdated.toLocaleTimeString()}`,
                      <Checkbox
                        checked={flavourInstance?.delisted}
                        onChange={(e) => {
                          setHasEditted(true);

                          setFlavourInstance((oldS) => {
                            if (!oldS) return oldS;
                            return { ...oldS, delisted: e.target.checked };
                          });
                        }}
                      />,

                      data.status === 'draft' ? (
                        <WarningIcon style={{ fill: theme.palette.warning.main }} />
                      ) : (
                        <Check style={{ fill: theme.palette.success.main }} />
                      ),
                    ],
                    id: data.id,
                  };
                })
            : []
        }
      />
    </Layout>
  );
};

export default AddDeploymentSKU;
