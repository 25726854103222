import { Actions, Box, Filedrop, Layout, MenuTabs } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';

const UploadCSV = () => {
  const [file, setFile] = useState<File | null>(null);

  const navigate = useNavigate();

  const links = [
    { label: 'Portfolio', value: 'portfolio', navigateTo: '/portfolio' },
    { label: 'Availability', value: 'availability', navigateTo: '/portfolio?tab=availability' },
  ];

  return (
    <Layout>
      <MenuTabs
        value={'portfolio'}
        onChange={(_, newValue) => navigate(links.filter((link) => link.value === newValue)[0].navigateTo)}
        links={links}
      />
      <Actions breadcrumbs={[{ label: 'Portfolio', path: '/portfolio' }, { label: 'Upload CSV' }]} />

      <Styled.Container>
        <Box title="Upload CSV">
          <Styled.Uploader>
            <Filedrop file={file} setFile={setFile} acceptedFiles={['.csv']} prompt="Drag and drop a CSV file here or click to browse" />
          </Styled.Uploader>
        </Box>

        <Actions
          ctas={[
            {
              type: 'button',
              variant: 'contained',
              label: 'Next',
              disabled: !file,
              onClick: () => navigate('/portfolio/upload-csv/preview', { state: { file } }),
            },
            {
              type: 'button',
              variant: 'outlined',
              label: 'Cancel',
              onClick: () => navigate('/portfolio'),
            },
          ]}
        />
      </Styled.Container>
    </Layout>
  );
};

export default UploadCSV;
