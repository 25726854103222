import styled from '@emotion/styled';

import theme from 'config/theme';

export const Header = styled.header<{ menuExpanded: boolean }>`
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: ${theme.headerHeight};
  background: ${theme.palette.primary.dark};
  display: grid;
  grid-template-columns: ${(props) => (props.menuExpanded === true ? theme.menuWidthExpanded : theme.menuWidth)} 1fr ${theme.menuWidth};
  transition: all linear 0.1s;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  display: block;
  overflow: hidden;

  svg {
    height: 40px;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    box-sizing: content-box;
  }
`;

export const Menu = styled.nav<{ menuExpanded: boolean }>`
  width: ${(props) => (props.menuExpanded === true ? theme.menuWidthExpanded : theme.menuWidth)};
  top: ${theme.headerHeight};
  background: white;
  position: fixed;
  height: calc(100vh - ${theme.headerHeight});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  overflow: scroll;
  transition: all linear 0.1s;
  overflow: hidden;
`;

export const Main = styled.main<{ menuExpanded: boolean }>`
  width: calc(100vw - ${(props) => (props.menuExpanded ? theme.menuWidthExpanded : theme.menuWidth)} - ${theme.spacing(12)});
  padding-top: calc(${theme.headerHeight} + ${theme.spacing(4)});
  padding-left: calc(${(props) => (props.menuExpanded ? theme.menuWidthExpanded : theme.menuWidth)} + ${theme.spacing(6)});
  padding-right: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(12)};
  transition: all linear 0.1s;
`;

export const Like = styled.div`
  border-top: 2px solid ${theme.palette.tertiary.light};
`;

export const Footer = styled.footer`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  width: calc(100vw - ${theme.menuWidth} - ${theme.spacing(8)});
  color: ${theme.palette.primary.main};
  padding: ${theme.spacing(2)};
  padding-left: calc(${theme.menuWidth} + ${theme.spacing(4)});
  padding-right: ${theme.spacing(4)};
  background: ${theme.palette.tertiary.light};
  z-index: 1;
  font-style: italic;
  font-size: 1rem !important;

  .MuiTypography-root {
    font-size: 1rem !important;
  }
`;
