import styled from '@emotion/styled';

import theme from 'config/theme';

const iconWidth = theme.spacing(8);

export const Outer = styled.div``;

export const Container = styled.div<{ isHeader: boolean; active: boolean }>`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)};
  background: ${(props) => (props.active ? theme.palette.tertiary.light : 'transparent')};
  color: ${(props) => (props.active ? theme.palette.primary.main : 'inherit')};
  cursor: pointer;
  transition: all linear 0.2s;
  grid-template-columns: ${iconWidth} 1fr;

  &:hover {
    background: ${(props) => (!props.isHeader ? theme.palette.tertiary.light : theme.palette.primary.main)};
  }

  > svg {
    transition: all linear 0.2s;
  }
`;

export const Icon = styled.label<{ color: string }>`
  display: flex;
  width: ${iconWidth};
  justify-content: center;
  cursor: pointer;

  svg {
    fill: ${(props) => props.color};
  }
`;

export const Label = styled.label`
  white-space: nowrap;
  cursor: pointer;
`;

export const ChildLabel = styled(Label)<{ isHeader?: boolean }>`
  display: block;
  padding: ${theme.spacing(2)} ${theme.spacing(10)} ${theme.spacing(2)} ${theme.spacing(10)};

  &:hover {
    background: ${(props) => (!props.isHeader ? theme.palette.tertiary.light : 'transparent')};
  }
`;

export const Toggle = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  left: 5px;

  svg {
    color: ${theme.palette.tertiary.main};
    transform: ${(props) => (props.isExpanded === true ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all linear 0.1s;
  }
`;
