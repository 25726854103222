import { Typography } from '@mui/material';
import { Children } from 'types/generic';

import * as Styled from './styles';

type BoxProps = {
  title?: string;
  titleVar?: string;
  children: Children;
  padded?: boolean;
};

export const Box: React.FC<BoxProps> = ({ title, titleVar, children, padded = true }) => {
  return (
    <Styled.Container padded={padded}>
      {title && (
        <Styled.Title>
          <Typography variant="h2">{title}</Typography>
          {titleVar && (
            <Typography variant="h2" color="primary" sx={{ marginLeft: '5px' }}>
              {titleVar}
            </Typography>
          )}
        </Styled.Title>
      )}
      {children}
    </Styled.Container>
  );
};
