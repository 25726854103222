import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from 'context';
import { AppProvider } from 'context/AppContext';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from 'utils/Router';

import theme from 'config/theme';

export const ShowMenuContext = React.createContext({});

const App = () => {
  React.useEffect(() => {
    document.body.style.background = theme.palette.tertiary.light;
    document.body.style.margin = '0';
  }, []);

  const [showFullMenu, setShowFullMenu] = React.useState<boolean>(false);

  return (
    <BrowserRouter>
      <SnackbarProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <ShowMenuContext.Provider value={{ value: showFullMenu, set: setShowFullMenu }}>
              <AppProvider>
                <Router />
              </AppProvider>
            </ShowMenuContext.Provider>
          </ThemeProvider>
        </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
