import { Alert, InputLabel } from '@mui/material';
import { IqosTravelData } from 'api/components/schemas';
import { Filedrop, FormElement, Loader, Modal } from 'components';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { uploadAsset } from 'utils/apiRequests/generic';
import { updateSkusForIqosTravel } from 'utils/apiRequests/skus';

import { IQOSTravelTableData } from 'views/IQOSTravel';

import theme from 'config/theme';

interface AssignSkuModalProps {
  row?: IQOSTravelTableData;
  show: boolean;
  onClose: () => void;
}

export const EditIqosContentModal: React.FC<AssignSkuModalProps> = ({ row, show, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const [colour, setColour] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const handleUploadImage = async () => {
    if (colour && row) {
      if (!file) {
        enqueueSnackbar('Please upload a file');
        return;
      }

      setIsLoading(true);

      const formData = new FormData();

      formData.append('file', file);

      const fileName = file.name;
      formData.append('name', fileName);

      // formData.append('tags', JSON.stringify(['hub2']));

      const resp = await uploadAsset(formData);
      if (resp) {
        const assetId = resp._id;
        const imageUrl = resp.url;

        enqueueSnackbar('Asset uploaded successfully', { variant: 'success' });
        onConfirm(assetId, imageUrl);
      } else {
        enqueueSnackbar('Error uploading asset', { variant: 'error' });
        setIsLoading(true);
      }
    } else {
      enqueueSnackbar('Asset or colour missing', { variant: 'error' });
    }
  };

  const onConfirm = async (assetId: string, imageUrl: string) => {
    setIsLoading(true);

    if (colour && row) {
      const iqosTravelContent: IqosTravelData = { assetId, colour, imageUrl };
      const skuIds = row.skus;

      setIsLoading(true);

      const resp = await updateSkusForIqosTravel(skuIds, iqosTravelContent);
      if (resp) {
        enqueueSnackbar('Successfully updated iqos travel content for sku');
        onClose();
      } else {
        enqueueSnackbar('Error updating iqos travel content for sku', { variant: 'error' });
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar('Asset or colour missing', { variant: 'error' });
    }
  };

  return (
    <Modal
      isOpen={show}
      title="Edit content for IQOS Travel"
      maxWidth={'lg'}
      content={
        <>
          {isLoading && <Loader />}
          {row?.iqosTravelData?.imageUrl && (
            // display a material ui alert which says that the image and colour is already uploaded
            <Alert severity="warning">
              <p>The image and colour is already uploaded. If you want to change it, please upload a new image and select a new colour.</p>
            </Alert>
          )}
          <div
            style={{
              marginTop: theme.spacing(2),
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '2fr 1fr',
              gridColumnGap: theme.spacing(4),
            }}>
            <Filedrop acceptedFiles={['.png', '.jpg', '.jpeg']} file={file} setFile={setFile} label="Unrestricted image" />

            <div>
              <InputLabel shrink>Restricted color</InputLabel>
              <FormElement type="color" label="HEX code" value={colour} onChange={setColour} />
            </div>
          </div>
        </>
      }
      actions={[
        {
          type: 'button',
          variant: 'contained',
          label: 'Save',
          onClick: handleUploadImage,
        },
        {
          type: 'button',
          variant: 'outlined',
          label: 'Discard Changes',
          onClick: onClose,
        },
      ]}
      onClose={onClose}
    />
  );
};
