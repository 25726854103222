import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteProps, SelectProps } from 'components';

type ChipSelectorProps = {
  id: string;
  label: string;
  chips: Array<{ label: string }>;
  options: SelectProps[];
  onChange: (val: AutocompleteProps[]) => void;
  disabled?: boolean;
  value: AutocompleteProps[];
};

export const ChipSelector: React.FC<ChipSelectorProps> = ({ id, label, options, chips = [], onChange, disabled, value }) => {
  return (
    <div>
      <Autocomplete
        disabled={disabled}
        id={id}
        options={options.map((opt) => ({ label: opt.name, id: opt.id }))}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} />}
        value={value}
        onChange={(_, newVal) => onChange(newVal)}
        multiple
      />
    </div>
  );
};
