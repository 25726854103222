import ArrowRight from '@mui/icons-material/ArrowRight';
import { Box } from 'components';
import { useState } from 'react';
import { Children } from 'types/generic';

import * as Styled from './styles';

export type AccordionProps = {
  children: Children;
  content: Children;
};

export const Accordion: React.FC<AccordionProps> = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Box padded={false}>
      <Styled.Head>
        <Styled.HeadContent>{children}</Styled.HeadContent>
        <Styled.Toggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <ArrowRight />
        </Styled.Toggle>
      </Styled.Head>
      <Styled.Content isOpen={isOpen}>{content}</Styled.Content>
    </Box>
  );
};
