import { Navigate, Route, Routes } from 'react-router-dom';

import AddDeployment from 'views/AddDeployment';
import AddDeploymentSKU from 'views/AddDeploymentSKU';
import AddInstance from 'views/AddInstance';
import AddSKU from 'views/AddSKU';
import EditSKU from 'views/EditSKU';
import Error from 'views/Error';
import FlavourNavigator from 'views/FlavourNavigator';
import IQOSTravel from 'views/IQOSTravel';
import Login from 'views/Login';
import Login2FA from 'views/Login2FA';
import MultiCategory from 'views/MultiCategory';
import PortfolioView from 'views/Portfolio';
import ResetPassword from 'views/ResetPassword';
import UploadCSV from 'views/UploadCSV';
import UploadCSVPreview from 'views/UploadCSVPreview';

import { RequireAuth } from 'components/Auth';

export const Router = () => {
  console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/portfolio" />} />
      <Route
        path="/portfolio"
        element={
          <RequireAuth>
            <PortfolioView />
          </RequireAuth>
        }
      />
      <Route
        path="/portfolio/add-sku"
        element={
          <RequireAuth>
            <AddSKU />
          </RequireAuth>
        }
      />
      <Route
        path="/portfolio/edit-sku"
        element={
          <RequireAuth>
            <EditSKU />
          </RequireAuth>
        }
      />
      <Route
        path="/portfolio/upload-csv"
        element={
          <RequireAuth>
            <UploadCSV />
          </RequireAuth>
        }
      />
      <Route
        path="/portfolio/upload-csv/preview"
        element={
          <RequireAuth>
            <UploadCSVPreview />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/flavor-navigator"
        element={
          <RequireAuth>
            <FlavourNavigator />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/flavor-navigator/add-deployment"
        element={
          <RequireAuth>
            <AddDeployment />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/flavor-navigator/add-deployment/skus"
        element={
          <RequireAuth>
            <AddDeploymentSKU />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/iqos-travel"
        element={
          <RequireAuth>
            <IQOSTravel />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/multi-category"
        element={
          <RequireAuth>
            <MultiCategory />
          </RequireAuth>
        }
      />
      <Route
        path="/digital-tools/add-instance"
        element={
          <RequireAuth>
            <AddInstance />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/two-factor" element={<Login2FA />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
};
