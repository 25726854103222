import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import { FlavourInstance, SKU } from 'api/components/schemas';
import { Actions, DataTable, Layout, Loader, Modal, Notifications } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllFlavourInstances } from 'utils/apiRequests/flavours';
import { getSkus } from 'utils/apiRequests/skus';

interface TableData {
  id: string | undefined;
  market: string | undefined;
  name: string;
  status: 'draft' | 'published' | 'archived';
}

const FlavourNavigator = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const navigate = useNavigate();
  const [showAssignModal, setShowAssignModal] = useState(false);

  const { markets } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const getData = async () => {
    setIsLoading(true);
    const flavourInstancesResp = await getAllFlavourInstances();
    const skusResp = await getSkus();
    if (flavourInstancesResp && skusResp) {
      const formattedData = formatDataForTable(skusResp, flavourInstancesResp);
      setTableData(formattedData);
    } else {
      enqueueSnackbar('Error fetching data', { variant: 'error' });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const formatDataForTable = (skusResp: SKU[], flavourInstanceResp: FlavourInstance[]) => {
    const formattedData = flavourInstanceResp.map((instance) => {
      const market = markets?.find((market) => market._id === instance.marketId);

      // create a string called name which you get by looping through products in the instance and finding the sku name from the sku id

      const name = instance.products.reduce((acc, curr) => {
        const skuName = skusResp?.find((sku) => sku._id === curr.skuId)?.skuName;
        return acc + skuName + ', ';
      }, '');

      return {
        id: instance._id,
        market: market?.name,
        name,
        status: instance.status,
      };
    });

    return formattedData;
  };

  return (
    <Layout>
      {isLoading ? <Loader /> : <></>}
      <Actions
        breadcrumbs={[{ label: 'Digital Tools', path: '/digital-tools' }, { label: 'Flavour Navigator' }]}
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Add instance',
            Icon: AddIcon,
            onClick: () => navigate('/digital-tools/flavor-navigator/add-deployment'),
          },
          {
            type: 'input',
            variant: 'outlined',
            label: 'Search',
            Icon: SearchIcon,
            isheader: 'true',
            onClick: () => {},
          },
        ]}
      />

      <Notifications
        severity="warning"
        message={
          <>
            ILUMA WE Edition' has been assigned to Japan Tokyo - Narita and is due to go live on 02/11/2023. Please update content for this SKU{' '}
            <span onClick={() => setShowAssignModal(true)}>here</span>
          </>
        }
        extra={13}
      />

      <DataTable
        columns={[
          {
            field: 'actions',
            headerName: '',
            width: 200,
            renderCell: (params) => {
              // render edit button as a material ui button
              console.log('params:', params);
              return (
                <Button
                  variant="outlined"
                  // add an edit icon to the start of the button
                  startIcon={<EditIcon />}
                  onClick={() => navigate('/digital-tools/flavor-navigator/add-deployment/skus', { state: { flavourInstanceId: params.row.id } })}>
                  Edit
                </Button>
              );
            },
          },
          { field: 'market', headerName: 'Market', width: 200 },
          { field: 'name', headerName: 'Name', width: 500 },
          { field: 'status', headerName: 'Status', width: 200 },
        ]}
        rows={tableData}
        selectable={false}
      />

      <Modal
        isOpen={showAssignModal}
        title="Assign ILUMA 'WE Edition' to locations."
        maxWidth={'lg'}
        content={
          <>
            {/* <ChipSelector id="market" label="Market" chips={[{ label: 'South Korea' }, { label: 'Japan' }]} />

            <ChipSelector
              id="locations"
              label="Locations"
              chips={[{ label: 'Osaka' }, { label: 'Haneda' }, { label: 'Chubu' }, { label: 'Narita' }]}
            /> */}
          </>
        }
        actions={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Continue',
            onClick: () => setShowAssignModal(false),
          },
          {
            type: 'button',
            variant: 'outlined',
            label: 'Cancel',
            onClick: () => setShowAssignModal(false),
          },
        ]}
        onClose={() => setShowAssignModal(false)}
      />
    </Layout>
  );
};

export default FlavourNavigator;
