import { Layout, MenuTabs } from 'components';
import { useState } from 'react';
import { useQuery } from 'utils/useQuery';

import Availablity from './Availability';
import Portfolio from './Portfolio';

const PortfolioView = () => {
  let query = useQuery();
  const [view, setView] = useState(query.size > 0 ? (query.get('tab') as string) : 'portfolio');

  const links = [
    { label: 'Portfolio', value: 'portfolio', component: <Portfolio /> },
    { label: 'Availability', value: 'availability', component: <Availablity /> },
  ];

  return (
    <Layout>
      <MenuTabs value={view} onChange={(_, newValue) => setView(newValue)} links={links} />

      {links.filter((link) => link.value === view)[0].component}
    </Layout>
  );
};

export default PortfolioView;
