import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { IconButton } from '@mui/material';
import { Actions, Layout, MCDataTable } from 'components';
import { Loader } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MultiCategory = () => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState([]);
  const [meta, setMeta] = useState();
  const navigate = useNavigate();

  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async (startKey = '') => {
    setLoading(true);
    let query = await fetch(
      `https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/getAllConfigs${startKey !== '' ? `?startKey=${startKey}` : ''}`,
    );
    let { response, meta } = await query.json();
    setConfig(response);
    setMeta(meta);
    setLoading(false);
  };

  const handleInstanceEdit = (row, index) => {
    const instance = config.find((instance) => instance.instanceName === row[0]);
    /* const configToEdit = instance.drafts[index]; */

    var configToEdit = {
      ...instance,
      ...instance.drafts[index],
    };

    // remove drafts and published
    configToEdit.drafts = [];
    configToEdit.published = {};

    navigate('/digital-tools/add-instance', { state: { instance: configToEdit } });
  };

  const handleInstancePublish = async (instance, index) => {
    const configToPublish = instance.drafts[index];

    const formattedValues = { ...instance };
    formattedValues.published = configToPublish;

    try {
      // delete the old languages object that is only used to build a new
      delete formattedValues.languages;
      formattedValues.publishedDate = new Date().toISOString();

      const response = await fetch('https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/storeConfigItem', {
        method: 'POST',
        body: JSON.stringify(formattedValues),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      fetchConfig();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Layout>
      <Actions
        breadcrumbs={[{ label: 'Multi Category CMS' }]}
        ctas={[
          {
            type: 'toggle',
            label: 'Show Archived',
            isChecked: showArchived,
            handleToggleArchived: () => setShowArchived(!showArchived),
          },
          {
            type: 'button',
            variant: 'contained',
            label: 'Add Instance',
            Icon: AddIcon,
            onClick: () => navigate('/digital-tools/add-instance'),
          },
        ]}
      />

      {loading ? (
        <Loader />
      ) : (
        config.map((instance) => {
          let locationName = '';
          instance.location.forEach((location) => {
            locationName += location.name + ', ';
          });
          return (
            <MCDataTable
              headings={['Instance Name', 'Version', 'Market', 'Location', 'URL', 'Published Date', 'Status']}
              data={instance.drafts.map((row, index) => [
                instance.instanceName,
                row.version,
                instance.market.name,
                locationName,
                `https://kiosk-master.d2jhtsq2vwp0km.amplifyapp.com/instance/${instance.instanceName}`,
                instance.published.version === row.version ? instance.publishedDate : 'Not Published',
                instance.published.version === row.version ? `Published` : index === instance.drafts.length - 1 ? 'Draft' : 'Archived',
              ])}
              showPagination={false}
              showArchived={showArchived}
              link="/portfolio"
              instanceEditAction={handleInstanceEdit}
              handlePublish={(index) => {
                handleInstancePublish(instance, index);
              }}
            />
          );
        })
      )}

      {!loading && (
        <>
          <IconButton onClick={() => fetchConfig()}>
            <ArrowLeftIcon fontSize="large" />
          </IconButton>
          <IconButton disabled={!meta?.lastEvaluatedKey?.PK?.S} onClick={() => fetchConfig(meta?.lastEvaluatedKey.PK.S)}>
            <ArrowRightIcon fontSize="large" />
          </IconButton>
        </>
      )}
    </Layout>
  );
};

export default MultiCategory;
