import EditIcon from '@mui/icons-material/Edit';
import { Autocomplete, FormControl, InputAdornment, InputLabel, InputProps, MenuItem, Select, SxProps, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { sortSelectOptions } from 'utils/array';

export type SelectProps = {
  id: string;
  name: string;
};

export type AutocompleteProps = { label: string; id: string };

export interface FormElementProps {
  type?: 'select' | 'datePicker' | 'textarea' | 'text' | 'color' | 'autocomplete';
  label: string;
  options?: Array<SelectProps>;
  onChange?: (val: string) => void;
  onAutocompleteChange?: (val: AutocompleteProps) => void;
  size?: string;
  sx?: SxProps;
  id?: string;
  value: string | Dayjs | null | AutocompleteProps;
  required?: boolean;
  disabled?: boolean;
  InputProps?: InputProps;
}

export const FormElement: React.FC<FormElementProps> = ({
  id,
  type = 'text',
  label,
  value,
  options = [],
  required = false,
  onChange = () => null,
  disabled,
  onAutocompleteChange = () => null,
  size,
  ...rest
}) => {
  if (type === 'select') {
    return (
      <FormControl size="small">
        <InputLabel id={`label-${id}`}>
          {label} {required && '*'}
        </InputLabel>
        <Select
          labelId={`label-${id}`}
          id={id}
          label={label}
          value={value}
          onChange={(e) => onChange(e.target.value as string)}
          disabled={disabled || options.length === 0}>
          {!required && (
            <MenuItem key={`no-option`} value={'null'}>
              No option
            </MenuItem>
          )}
          {sortSelectOptions(options).map((opt: SelectProps, count: number) => (
            <MenuItem key={`${id}-opt-${count}`} value={opt.id}>
              {opt.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === 'datePicker') {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker value={value} label={label} slotProps={{ textField: { size: 'small' } }} onChange={(date) => onChange(date as string)} />
      </LocalizationProvider>
    );
  } else if (type === 'autocomplete') {
    return (
      <Autocomplete
        disabled={disabled}
        id={id}
        options={options.map((opt) => ({ label: opt.name, id: opt.id }))}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={label} />}
        value={value as AutocompleteProps}
        onChange={(_, newVal) => onAutocompleteChange(newVal as AutocompleteProps)}
      />
    );
  } else {
    return (
      <TextField
        id={id}
        size="small"
        label={label}
        required={required}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ height: '100%', display: 'flex', alignSelf: type === 'textarea' ? 'flex-start' : 'center' }}>
              <EditIcon />
            </InputAdornment>
          ),
          startAdornment: type === 'color' && (
            <>
              <div
                style={{
                  width: '25px',
                  height: '20px',
                  marginRight: '10px',
                  borderRadius: '5px',
                  border: '1px solid #000',
                  background: `#${value}`,
                }}
              />
              #
            </>
          ),
        }}
        multiline={type === 'textarea' ? true : false}
        rows={4}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
    );
  }
};
