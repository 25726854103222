import { createTheme } from '@mui/material/styles';

declare module '@mui/material' {
  interface PaletteColorOptions {
    main: string;
    dark: string;
    light: string;
    contrast?: string;
  }

  interface Palette {
    tertiary: PaletteColorOptions;
  }

  interface Theme {
    headerHeight: string;
    menuWidth: string;
    menuWidthExpanded: string;
  }
}

type RootFunc = {
  ownerState: any;
};

let theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: 'Roboto',
  },
});

theme = createTheme(theme, {
  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      main: '#2AB0FD',
      dark: '#4FA1D9',
    },
    secondary: {
      ...theme.palette.secondary,
      main: '#ffffff',
    },
  },
  headerHeight: '75px',
  menuWidth: theme.spacing(12),
  menuWidthExpanded: '220px',
});

// Custom colors created with augmentColor go here
theme = createTheme(theme, {
  palette: {
    tertiary: theme.palette.augmentColor({
      color: {
        main: '#757575',
        dark: '#E4E4E4',
        light: '#FAFAFA',
        contrast: '#A9A9A9',
      },
      name: 'tertiary',
    }),
  },
});

// Component style overrides
theme = createTheme(theme, {
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '9px',
        },
        icon: {
          padding: 0,
          alignItems: 'center',
        },
        message: {
          color: '#000',
        },
        standardError: {
          a: {
            color: theme.palette.error.main,
          },
        },
        standardInfo: {
          a: {
            color: theme.palette.info.main,
          },
        },
        standardSuccess: {
          a: {
            color: theme.palette.success.main,
          },
        },
        standardWarning: {
          a: {
            color: theme.palette.warning.main,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '1rem !important',
          fontWeight: 300,
          color: theme.palette.tertiary.contrast,
          '.MuiTypography-root': {
            fontSize: '1rem !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '9px',
          padding: 0,
          '&.MuiButton-contained, &.MuiButton-outlined': {
            minWidth: '150px',
            padding: `${theme.spacing(1)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(2)}`,
          },
        },
        iconSizeMedium: {
          '>*:nth-of-type(1)': {
            fontSize: 30,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '9px',
          padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          padding: `0 ${theme.spacing(3)}`,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          display: 'grid',
          justifyContent: 'flex-start',
          rowGap: theme.spacing(4),
          paddingTop: `${theme.spacing(2)} !important`,
        },
      },
    },
    MuiFooter: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
          '&.MuiTypography-root': {
            fontSize: '2rem !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          '.MuiTypography-root': {
            fontSize: '1rem !important',
            fontWeight: 'bold',
          },
          '.MuiCheckbox-root': {
            paddingLeft: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': ({ ownerState }: RootFunc) => ({
            ...(ownerState.isheader === 'true' && {
              color: theme.palette.primary.main,
            }),
          }),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: 'all linear 0.2s',
          fontSize: '1rem !important',
          '&.MuiOutlinedInput-root': {
            borderRadius: '9px',
          },
          '&.Mui-disabled': {
            background: theme.palette.tertiary.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        fontSize: '1rem !important',
        shrink: {
          top: '-5px',
        },
        formControl: {
          fontSize: '1rem !important',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          transform: 'translateY(-10px) !important',
          borderRadius: '9px',
          padding: `0 ${theme.spacing(2)}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem !important',
          color: theme.palette.tertiary.main,
          padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
          borderBottom: `1px solid ${theme.palette.tertiary.dark}`,
          '&:last-of-type': {
            border: 0,
          },
          '&.Mui-selected': {
            background: 'transparent',
            color: theme.palette.primary.main,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
          padding: `0 ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.tertiary.main,
          whiteSpace: 'nowrap',
          padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} 0`,
        },
        head: {
          fontWeight: 'bold',
          borderBottom: `1px solid ${theme.palette.tertiary.main} !important`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          color: theme.palette.tertiary.contrast,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-of-type': {
            '.MuiTableCell-root': {
              border: 0,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&:after': {
            position: 'absolute',
            zIndex: '-1',
            bottom: '4px',
            left: '1px',
            width: '100%',
            content: '""',
            height: '1px',
            background: theme.palette.tertiary.dark,
          },
        },
        indicator: {
          height: '5px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }: RootFunc) => ({
          width: '100%',
          ...(ownerState.isheader === 'true' && {
            '.MuiInputLabel-root': {
              color: theme.palette.primary.main,
            },
            '.MuiInputAdornment-root': {
              color: theme.palette.primary.main,
            },
          }),
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: theme.palette.tertiary.main,
          '&.MuiTypography-h1, &.MuiTypography-h2, &.MuiTypography-h3, &.MuiTypography-h4, &.MuiTypography-h5, &.MuiTypography-h6': {
            fontWeight: 'bold',
          },
        },
        h2: {
          fontSize: 20,
        },
      },
    },
  },
});

export default theme;
