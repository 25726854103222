import { IqosTravelData, SKU } from 'api/components/schemas';
import axios from 'axios';
import { getAccessToken, handleApiError } from 'utils/helpers';

export const getSkus = async (locationIds?: string[]) => {
  try {
    const accessToken = await getAccessToken();

    // add cors headers to reqOptions
    const reqOptions: any = {
      headers: {
        'api-key': accessToken,
        'Content-Type': 'application/json',
      },
    };

    if (locationIds) {
      reqOptions.params = { locationIds };
    }

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/skus`, reqOptions);

    return resp.data as SKU[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};

export const getSkusForIqosTravel = async (locationIds?: string[]) => {
  try {
    const accessToken = await getAccessToken();

    const reqOptions: any = { headers: { 'api-key': accessToken } };

    if (locationIds) {
      reqOptions.params = { locationIds };
    }

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/skus_iqos_travel`, reqOptions);

    return resp.data as Record<string, SKU[]>;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};

export const updateSkusForIqosTravel = async (skuIds: string[], iqosTravelData: IqosTravelData) => {
  try {
    const accessToken = await getAccessToken();

    await axios.put(`${process.env.REACT_APP_API_URL}/skus_iqos_travel`, { skuIds, iqosTravelData }, { headers: { 'api-key': accessToken } });

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const createSku = async (sku: SKU) => {
  try {
    const accessToken = await getAccessToken();

    await axios.post(`${process.env.REACT_APP_API_URL}/sku`, sku, { headers: { 'api-key': accessToken } });

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const bulkUploadSkus = async (skus: SKU[]) => {
  try {
    const accessToken = await getAccessToken();

    await axios.post(`${process.env.REACT_APP_API_URL}/bulk_sku`, skus, { headers: { 'api-key': accessToken } });

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const updateSkus = async (sku: SKU[]) => {
  try {
    const accessToken = await getAccessToken();

    await axios.put(`${process.env.REACT_APP_API_URL}/skus`, sku, { headers: { 'api-key': accessToken } });

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const getSkuAlerts = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/sku_alerts`, { headers: { 'api-key': accessToken } });

    return resp.data as SKU[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};

export const updateSku = async (sku: SKU) => {
  try {
    const accessToken = await getAccessToken();

    await axios.put(`${process.env.REACT_APP_API_URL}/sku`, sku, { headers: { 'api-key': accessToken } });

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
