import { SKU } from 'api/components/schemas';
import { User } from 'types/generic';

import { refreshToken } from './apiRequests/token';

export const getAccessToken = () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).access_token : null;
  return token;
};
export const getRefreshToken = () => {
  const user = localStorage.getItem('user');
  const token = user ? JSON.parse(user).refresh_token : null;
  return token;
};

export const setUserInLocalStorage = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const reomveUserInLocalStorage = () => {
  localStorage.removeItem('user');
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('user');
  return user ? (JSON.parse(user) as User) : null;
};

export const handleApiError = async (error: any) => {
  console.log('error:', error);
  console.log('error:', error?.status);

  if (
    (error?.response?.status === 401 && error?.response?.data?.error?.type === 'request_validation') ||
    error?.response?.data?.errorName === 'FailedToAuthenticateToken'
  ) {
    const resp = await refreshToken();
    if (resp) {
      window.location.reload();
    } else {
      reomveUserInLocalStorage();
      window.location.reload();
    }
  }
};

export const errorsInSku = (sku: SKU) => {
  const {
    sfpPlatformId,
    brandFamilyId,
    designMarketId,
    productGenerationId,
    globalDelistDate,
    globalLaunchDate,
    productTypeId,
    skuName,
    smdlCode,
  } = sku;

  if (!sfpPlatformId) {
    return 'SFP Platform is required';
  }
  if (!brandFamilyId) {
    return 'Brand Family is required';
  }
  if (!designMarketId) {
    return 'Design Market is required';
  }
  if (!productGenerationId) {
    return 'Product Generation is required';
  }
  if (!globalDelistDate || !(globalDelistDate instanceof Date)) {
    return 'Delist Date is required and must be a valid Date';
  }
  if (!globalLaunchDate || !(globalLaunchDate instanceof Date)) {
    return 'Launch Date is required and must be a valid Date';
  }
  if (!productTypeId) {
    return 'Product Type is required';
  }
  if (!skuName) {
    return 'SKU Name is required';
  }
  if (!smdlCode) {
    return 'SMDL Code is required';
  }
  // if (!similarProductIds || !similarProductIds.length) {
  //   return 'Similar Products is required';
  // }

  return false;
};

export const isSkuActive = (sku: SKU): boolean => {
  const currentDate = new Date();
  const launchDate = new Date(sku.globalLaunchDate);
  const delistDate = new Date(sku.globalDelistDate);

  return currentDate >= launchDate && currentDate <= delistDate;
};

export const formatDate = (date: Date | string) => {
  date = new Date(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const genRandomaUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const trimAndLowerCase = (str: string) => {
  return str.trim().toLowerCase();
};
