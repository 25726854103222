import styled from '@emotion/styled';

import theme from 'config/theme';

export const BoxPadding = `${theme.spacing(2)} ${theme.spacing(3)}`;

export const Container = styled.div<{ padded: boolean }>`
  display: grid;
  background: #fff;
  border: 1px solid ${theme.palette.tertiary.dark};
  border-radius: 9px;
  padding: ${(props) => (props.padded === true ? BoxPadding : 0)};
  row-gap: ${(props) => (props.padded === true ? theme.spacing(4) : 0)};
`;

export const Title = styled.div`
  display: flex;

  > * {
    margin-right: ${theme.spacing(1)};
  }
`;
