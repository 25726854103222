import { Typography } from '@mui/material';
import { EVaporProduct, HeatedTobaccoProduct, HeatedTobaccoProductWithBan, NicotinePouchesProduct, SKU } from 'api/components/schemas';
import { Actions, DataTable, Layout, Loader, MenuTabs } from 'components';
import { useAppContext } from 'context/AppContext';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { bulkUploadSkus, getSkus } from 'utils/apiRequests/skus';
import { genRandomaUuid, trimAndLowerCase } from 'utils/helpers';

const verifyHeaders = (headers: string[]) => {
  const requiredHeaders = [
    'smdlCode',
    'skuName',
    'designMarket',
    'sfpPlatform',
    'globalLaunchDate',
    'globalDelistDate',
    'productGeneration',
    'productType',
    'productLine',
    'brandFamily',
    'similarProductIds',
    'productType',
    'body',
    'cooling',
    'intensity',
    'aroma',
    'nicotineLevel',
    'nicotine',
    'strength',
    'format',
    'category',
  ];
  const missingHeaders: string[] = [];
  headers.forEach((header) => {
    if (!requiredHeaders.includes(header)) {
      missingHeaders.push(header);
    }
  });
  if (missingHeaders.length > 0) {
    alert(
      `The CSV file you've uploaded is not in the correct format. Please upload a CSV file with the correct headers. The following headers are missing or invalid: ${missingHeaders.join(
        ', ',
      )}`,
    );
    return false;
  } else {
    return true;
  }
};

const UploadCSVPreview = () => {
  const [currSkus, setCurrSkus] = useState<SKU[] | null>(null);
  const [newSkus, setNewSkus] = useState<SKU[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newSkusForTable, setNewSkusForTable] = useState<SKU[]>([]);
  const navigate = useNavigate();

  const { sfpPlatforms, productGenerations, productTypes, brandFamilies, productLines, designMarkets } = useAppContext();

  const location = useLocation();
  const file = location.state.file;

  console.log('file:', file);

  const getSkusHelper = async () => {
    setIsLoading(true);
    const resp = await getSkus();
    console.log('get skus resp:', resp);
    if (resp) {
      setCurrSkus(resp);
    }
    setIsLoading(false);
  };

  function parseCsvToSKUs(csvData: string): SKU[] | null {
    const lines = csvData.split('\n');
    const headers = lines[0].trim().split(',');
    const skus: SKU[] = [];

    console.log('headers:', headers);

    const isValid = verifyHeaders(headers);

    try {
      if (!currSkus) {
        enqueueSnackbar('Failed to get current SKUs', { variant: 'error' });
        throw new Error('Failed to get current SKUs');
      }

      if (!isValid) {
        enqueueSnackbar('Invalid CSV', { variant: 'error' });
        throw new Error('Invalid CSV');
      }

      console.log('lines:', lines);

      for (let i = 1; i < lines.length - 1; i++) {
        console.log('lines[i]:', lines[i]);
        console.log('lines[i]:', i);
        const values = lines[i].trim().split(',');
        console.log('values:', values);
        let sku: Partial<SKU> = {
          locationLaunchDates: [],
        };

        //fields to be a heated tobbaco product
        const indexOfBody = headers.indexOf('body');
        const indexOfCooling = headers.indexOf('cooling');
        const indexOfIntensity = headers.indexOf('intensity');
        const indexOfAroma = headers.indexOf('aroma');
        const indexOfCategory = headers.indexOf('category');

        //fields for e vapour product
        const indexOfNicotineLevel = headers.indexOf('nicotineLevel');
        const indexOfNicotine = headers.indexOf('nicotine');
        //  and category

        // fields for nicotine pouches
        const indexOfStrength = headers.indexOf('strength');
        const indexOfFormat = headers.indexOf('format');

        const indexesToSkip = [
          indexOfBody,
          indexOfCooling,
          indexOfIntensity,
          indexOfAroma,
          indexOfCategory,
          indexOfNicotineLevel,
          indexOfNicotine,
          indexOfStrength,
          indexOfFormat,
        ];

        if (!values[indexOfBody] && !values[indexOfCooling] && !values[indexOfAroma] && values[indexOfIntensity] && values[indexOfCategory]) {
          console.log('this is a heated tobacco product with ban');
          const heatedTobaccoProduct: HeatedTobaccoProductWithBan = {
            intensity: Number(values[indexOfIntensity]),
            category: values[indexOfCategory].toLowerCase() as HeatedTobaccoProductWithBan['category'],
          };
          sku.product = heatedTobaccoProduct;
        }

        if (values[indexOfBody] && values[indexOfCooling] && values[indexOfIntensity] && values[indexOfAroma] && values[indexOfCategory]) {
          console.log('this is a heated tobacco product');
          const heatedTobaccoProduct: HeatedTobaccoProduct = {
            body: Number(values[indexOfBody]),
            cooling: Number(values[indexOfCooling]),
            intensity: Number(values[indexOfIntensity]),
            aroma: Number(values[indexOfAroma]),
            category: values[indexOfCategory].toLowerCase() as HeatedTobaccoProduct['category'],
          };
          sku.product = heatedTobaccoProduct;
        }

        if (values[indexOfNicotineLevel] && values[indexOfNicotine] && values[indexOfCategory]) {
          console.log('this is a e vapour product');
          const eVapourProduct: EVaporProduct = {
            category: values[indexOfCategory].toLowerCase() as EVaporProduct['category'],
            nicotine: Number(values[indexOfNicotine]),
            nicotineLevel: Number(values[indexOfNicotineLevel]),
          };
          sku.product = eVapourProduct;
        }

        if (values[indexOfNicotine] && values[indexOfStrength] && values[indexOfFormat]) {
          console.log('this is a e nicotine pouch product');
          const nicotinePouchesProduct: NicotinePouchesProduct = {
            nicotine: Number(values[indexOfNicotine]),
            strength: values[indexOfStrength] as NicotinePouchesProduct['strength'],
            format: values[indexOfFormat] as NicotinePouchesProduct['format'],
          };
          sku.product = nicotinePouchesProduct;
        }

        headers.forEach((header, index) => {
          if (indexesToSkip.includes(index)) {
            return;
          }

          const value = values[index];
          switch (header) {
            case 'smdlCode':
              console.log('currSkus:', currSkus);
              console.log('value:', value);

              const isDuplicate = currSkus?.find((sku) => trimAndLowerCase(sku.smdlCode) === trimAndLowerCase(value));
              if (isDuplicate) {
                enqueueSnackbar(`SKU in row: ${index - 1} with value: "${value}" is a duplicate`, { variant: 'error' });
                throw new Error('Duplicate SKU');
              }
              sku['smdlCode'] = value;
              break;
            case 'designMarket':
              const designMarketId = designMarkets?.find((dm) => trimAndLowerCase(dm.name) === trimAndLowerCase(value))?._id;
              if (!designMarketId) {
                enqueueSnackbar(`Design Market in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("Design Market doesn't exist");
              }
              sku['designMarketId'] = designMarketId;
              break;
            case 'sfpPlatform':
              const sfpPlatformId = sfpPlatforms?.find((sfp) => trimAndLowerCase(sfp.name) === trimAndLowerCase(value))?._id;
              if (!sfpPlatformId) {
                enqueueSnackbar(`SFP Platform in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("SFP Platform doesn't exist");
              }
              sku['sfpPlatformId'] = sfpPlatformId;
              break;
            case 'productGeneration':
              const productGenerationId = productGenerations?.find((pg) => trimAndLowerCase(pg.name) === trimAndLowerCase(value))?._id;
              if (!productGenerationId) {
                enqueueSnackbar(`Product Generation in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("Product Generation doesn't exist");
              }
              sku['productGenerationId'] = productGenerationId;
              break;
            case 'productType':
              const productTypeId = productTypes?.find((pt) => trimAndLowerCase(pt.name) === trimAndLowerCase(value))?._id;
              if (!productTypeId) {
                enqueueSnackbar(`Product Type in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("Product Type doesn't exist");
              }
              sku['productTypeId'] = productTypeId;
              break;
            case 'brandFamily':
              const brandFamilyId = brandFamilies?.find((bf) => trimAndLowerCase(bf.name) === trimAndLowerCase(value))?._id;
              if (!brandFamilyId) {
                enqueueSnackbar(`Brand Family in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("Brand Family doesn't exist");
              }
              sku['brandFamilyId'] = brandFamilyId;
              break;
            case 'productLine':
              if (!value) break;
              const productLineId = productLines?.find((pl) => trimAndLowerCase(pl.name) === trimAndLowerCase(value))?._id;
              if (!productLineId) {
                enqueueSnackbar(`Product Line in row: ${index - 1} with value: "${value}" doesn't exist`, { variant: 'error' });
                throw new Error("Product Line doesn't exist");
              }
              sku['productLineId'] = productLineId;
              break;
            case 'similarProductIds':
              sku[header] = value ? value.split(';') : [];
              break;
            case 'globalDelistDate':
              sku[header] = new Date(value);
              break;
            case 'globalLaunchDate':
              sku[header] = new Date(value);
              break;
            default:
              // @ts-ignore as its a valid field
              sku[header] = value;
          }
        });

        skus.push(sku as SKU);
      }
    } catch (error) {
      // navigate('/portfolio/upload-csv');
      console.log('error:', error);
      return null;
    }

    return skus;
  }

  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && typeof event.target.result === 'string') {
          resolve(event.target.result);
        } else {
          reject(new Error('File could not be read as text'));
        }
      };

      reader.onerror = (event) => {
        reject(new Error('Error reading file'));
      };

      reader.readAsText(file);
    });
  };

  const formatSkusforTable = (skus: SKU[]) => {
    return skus.map((sku) => ({
      ...sku,
      id: genRandomaUuid(),
      sfpPlatform: sfpPlatforms?.find((sfp) => sfp._id === sku.sfpPlatformId)?.name || '-',
      productGeneration: productGenerations?.find((pg) => pg._id === sku.productGenerationId)?.name || '-',
      productType: productTypes?.find((pt) => pt._id === sku.productTypeId)?.name || '-',
      brandFamily: brandFamilies?.find((bf) => bf._id === sku.brandFamilyId)?.name || '-',
      productLine: productLines?.find((pl) => pl._id === sku.productLineId)?.name || '-',
      designMarket: designMarkets?.find((dm) => dm._id === sku.designMarketId)?.name || '-',
    }));
  };

  const readFileHelper = async () => {
    if (file) {
      const resp = await readFileAsText(file);
      const skusResp = parseCsvToSKUs(resp);
      if (skusResp) {
        setNewSkus(skusResp);
        const formattedNewSkus = formatSkusforTable(skusResp);
        setNewSkusForTable(formattedNewSkus);

        console.log('skusResp:', skusResp);
      }
    }
  };

  const onConfirmClick = async () => {
    setIsLoading(true);
    const resp = await bulkUploadSkus(newSkus);
    if (resp) {
      enqueueSnackbar('Successfully uploaded new SKUs', { variant: 'success' });
      navigate('/portfolio');
    } else {
      enqueueSnackbar('Failed to upload new SKUs', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (currSkus) {
      readFileHelper();
    }
  }, [file, currSkus]);

  useEffect(() => {
    getSkusHelper();
  }, []);

  const links = [
    { label: 'Portfolio', value: 'portfolio', navigateTo: '/portfolio' },
    { label: 'Availability', value: 'availability', navigateTo: '/portfolio?tab=availability' },
  ];

  return (
    <Layout>
      <MenuTabs
        value={'portfolio'}
        onChange={(_, newValue) => navigate(links.filter((link) => link.value === newValue)[0].navigateTo)}
        links={links}
      />
      <Actions breadcrumbs={[{ label: 'Portfolio', path: '/portfolio' }, { label: 'Upload CSV' }]} />

      <Typography variant="h2">Data Preview</Typography>
      {isLoading ? <Loader /> : <></>}

      {newSkusForTable && (
        <DataTable
          columns={[
            { field: 'sfpPlatform', headerName: 'SFP Platform', width: 200 },
            { field: 'productGeneration', headerName: 'Product Generation', width: 200 },
            { field: 'productType', headerName: 'Product Type', width: 200 },
            { field: 'brandFamily', headerName: 'Brand Family', width: 200 },
            { field: 'productLine', headerName: 'Product Line', width: 200 },
            { field: 'skuName', headerName: 'SKU Name', width: 200 },
            { field: 'smdlCode', headerName: 'SMDL Code', width: 200 },
            { field: 'designMarket', headerName: 'Design Market', width: 200 },
          ]}
          rows={newSkusForTable}
        />
      )}
      <Actions
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Confirm & Upload',
            disabled: !file,
            onClick: onConfirmClick,
          },
          {
            type: 'button',
            variant: 'outlined',
            label: 'Cancel',
            onClick: () => navigate('/portfolio/upload-csv'),
          },
        ]}
      />
    </Layout>
  );
};

export default UploadCSVPreview;
