import styled from '@emotion/styled';

import theme from 'config/theme';

export const Checkboxes = styled.div`
  display: flex;
  margin: ${theme.spacing(2)} 0;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  column-gap: ${theme.spacing(4)};
`;
