import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { MenuItem } from 'components';
import { ComponentType } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type MenuListChildProps = {
  name: string;
  path: string;
};

type MenuListProps = {
  name: string;
  icon: ComponentType;
  path: string;
  children?: MenuListChildProps[];
};

type MenuProps = {
  isExpanded: boolean;
};

const MenuList: MenuListProps[] = [
  // {
  //   name: 'Home',
  //   icon: HomeOutlinedIcon,
  //   path: '/',
  // },
  {
    name: 'Portfolio',
    icon: ViewInArOutlinedIcon,
    path: '/portfolio',
  },
  {
    name: 'Digital Tools',
    path: '/digital-tools',
    icon: HomeRepairServiceIcon,
    children: [
      {
        name: 'Flavor Navigator',
        path: '/digital-tools/flavor-navigator',
      },
      {
        name: 'IQOS Travel',
        path: '/digital-tools/iqos-travel',
      },
      {
        name: 'Multi Category',
        path: '/digital-tools/multi-category',
      },
    ],
  },
  // {
  //   name: 'Deployments',
  //   path: '/deployments',
  //   icon: DesktopWindowsOutlinedIcon,
  // },
  // {
  //   name: 'Locations',
  //   path: '/location',
  //   icon: FmdGoodOutlinedIcon,
  // },
  // {
  //   name: 'Asset Library',
  //   path: '/asset-library',
  //   icon: ImageOutlinedIcon,
  // },
];

export const Menu: React.FC<MenuProps> = ({ isExpanded }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      {MenuList.map((item, count) => (
        <MenuItem
          key={`menu-item-${count}`}
          htmlKey={`menu-item-${count}`}
          onClick={() => navigate(item.path)}
          path={item.path}
          active={`/${location.pathname.split('/')[1]}` === item.path}
          Icon={item.icon}
          label={item.name}
          showLabel={isExpanded}
          children={item.children}
        />
      ))}
    </div>
  );
};
