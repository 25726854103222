import type { DesignMarket, Location, Market } from 'api/components/schemas';
import axios from 'axios';
import { getAccessToken, handleApiError } from 'utils/helpers';

export const getDesignMarkets = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/design_markets`, { headers: { 'api-key': accessToken } });

    return resp.data as DesignMarket[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const getLocations = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_HUB_API_URL}/physicalLocation/location`, {
      headers: { 'api-key': accessToken, Authorization: `Bearer ${accessToken}` },
      params: { perPage: 10000, pageNum: 1 },
    });

    return resp.data.results as Location[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const getMarkets = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_HUB_API_URL}/market`, {
      headers: { 'api-key': accessToken, Authorization: `Bearer ${accessToken}` },
    });

    return resp.data as Market[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
