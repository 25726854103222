import { Attributes, DesignMarket, EVaporProduct, HeatedTobaccoProduct, NicotinePouchesProduct, SKU, SfpPlatform } from 'api/components/schemas';
import { Columns, FormElement } from 'components';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { getAttributes } from 'utils/apiRequests/generic';

interface ProductAttributesProps {
  selectedDesignMarket: DesignMarket | null;
  selectedSfpPlatform: SfpPlatform | null;
  setNewSku: React.Dispatch<React.SetStateAction<SKU>>;
}

export const ProductAttributes: React.FC<ProductAttributesProps> = ({ selectedSfpPlatform, selectedDesignMarket, setNewSku }) => {
  const [attributes, setAttributes] = useState<Attributes | null>(null);

  const [heatedTobaccoProduct, setHeatedTobaccoProduct] = useState<HeatedTobaccoProduct>({
    aroma: 0,
    body: 0,
    category: 'tobacco',
    cooling: 0,
    intensity: 0,
  });

  const [evapourProduct, setEvapourProduct] = useState<EVaporProduct>({
    category: 'warm',
    nicotine: 0,
    nicotineLevel: 0,
  });

  const [nicotinePouchesProduct, setNicotinePouchesProduct] = useState<NicotinePouchesProduct>({
    format: 'dry',
    nicotine: 0,
    strength: 'strong',
  });

  const { enqueueSnackbar } = useSnackbar();

  const getAttributesHelper = async () => {
    const resp = await getAttributes();
    if (resp) {
      setAttributes(resp);
    } else {
      enqueueSnackbar('Error fetching attributes');
    }
  };

  useEffect(() => {
    getAttributesHelper();
  }, []);

  const onHeatedTobaccoProductChange = (key: keyof HeatedTobaccoProduct, value: string | number) => {
    setHeatedTobaccoProduct((oldS) => {
      const newState = { ...oldS, [key]: value };
      setNewSku((oldSku) => ({ ...oldSku, product: newState }));
      return newState;
    });
  };
  const onEVapourProductChange = (key: keyof EVaporProduct, value: string | number) => {
    setEvapourProduct((oldS) => {
      const newState = { ...oldS, [key]: value };
      setNewSku((oldSku) => ({ ...oldSku, product: newState }));
      return newState;
    });
  };
  const onNicotineProductChange = (key: keyof NicotinePouchesProduct, value: string | number) => {
    setNicotinePouchesProduct((oldS) => {
      const newState = { ...oldS, [key]: value };
      setNewSku((oldSku) => ({ ...oldSku, product: newState }));
      return newState;
    });
  };

  const selectNumberOptions = [0, 1, 2, 3, 4, 5].map((value) => ({ id: String(value), name: String(value) }));
  const heatedTobaccoCategories = attributes?.heatedTobaccoCategories?.map((value) => ({ id: value.value, name: value.label }));

  const eVapourCategories = attributes?.eVapourCategories?.map((value) => ({ id: value.value, name: value.label }));

  const nicotinePouchesStrength = attributes?.nicotinePouchesStrength?.map((value) => ({ id: value.value, name: value.label }));

  const nicotinePouchesFormat = attributes?.nicotinePouchesFormat?.map((value) => ({ id: value.value, name: value.label }));

  return (
    <>
      {selectedSfpPlatform?.name === 'Heated Tobacco' && (
        <Columns>
          <FormElement
            type="select"
            id="intensity"
            label="Intensity"
            options={selectNumberOptions}
            onChange={(val) => onHeatedTobaccoProductChange('intensity', Number(val))}
            value={String(heatedTobaccoProduct.intensity)}
            required
          />

          <FormElement
            type="select"
            id="body"
            label="Body"
            options={selectNumberOptions}
            value={String(heatedTobaccoProduct.body)}
            onChange={(val) => onHeatedTobaccoProductChange('body', Number(val))}
            required
          />

          <FormElement
            type="select"
            id="cooling"
            label="Cooling"
            options={selectNumberOptions}
            value={String(heatedTobaccoProduct.cooling)}
            onChange={(val) => onHeatedTobaccoProductChange('cooling', Number(val))}
            required
          />

          <FormElement
            type="select"
            id="aroma"
            label="Aroma"
            options={selectNumberOptions}
            onChange={(val) => onHeatedTobaccoProductChange('aroma', Number(val))}
            value={String(heatedTobaccoProduct.aroma)}
            required
          />

          <FormElement
            type="select"
            id="category"
            label="Category"
            options={heatedTobaccoCategories}
            onChange={(val) => onHeatedTobaccoProductChange('category', val)}
            value={heatedTobaccoProduct.category}
            required
          />
        </Columns>
      )}

      {selectedSfpPlatform?.name === 'E Vapour' && (
        <Columns>
          <FormElement
            type="text"
            id="nicotineLevel"
            label="Nicotine level (%)"
            onChange={(val) => onEVapourProductChange('nicotineLevel', Number(val))}
            value={String(evapourProduct.nicotineLevel)}
          />

          <FormElement
            type="text"
            id="nicotine"
            label="Nicotine (mg)"
            onChange={(val) => onEVapourProductChange('nicotine', Number(val))}
            value={String(evapourProduct.nicotine)}
          />

          <FormElement
            type="select"
            id="category"
            label="Category"
            options={eVapourCategories}
            onChange={(val) => onEVapourProductChange('category', val)}
            value={evapourProduct.category}
          />
        </Columns>
      )}
      {selectedSfpPlatform?.name === 'Nicotine Pouches' && (
        <Columns>
          <FormElement
            type="text"
            id="nicotine"
            label="Nicotine (mg)"
            onChange={(val) => onNicotineProductChange('nicotine', Number(val))}
            value={String(nicotinePouchesProduct.nicotine)}
          />

          <FormElement
            type="select"
            id="strength"
            label="Strength"
            options={nicotinePouchesStrength}
            onChange={(val) => onNicotineProductChange('strength', val)}
            value={String(nicotinePouchesProduct.strength)}
          />
          <FormElement
            type="select"
            id="format"
            label="Format"
            options={nicotinePouchesFormat}
            onChange={(val) => onNicotineProductChange('format', val)}
            value={String(nicotinePouchesProduct.format)}
          />
        </Columns>
      )}
    </>
  );
};
