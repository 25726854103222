import styled from '@emotion/styled';

import theme from 'config/theme';

export const Container = styled.div`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
`;

export const Uploader = styled.div`
  padding: 0 ${theme.spacing(12)} ${theme.spacing(8)} ${theme.spacing(12)};
`;
