import { Refresh } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import { SKU } from 'api/components/schemas';
import { Actions, DataTable } from 'components';
import { useAppContext } from 'context/AppContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSkus } from 'utils/apiRequests/skus';
import { isSkuActive } from 'utils/helpers';

interface TableData extends SKU {
  listingStatus: string;
  id: string;
}

const Portfolio = () => {
  const [skus, setSkus] = useState<TableData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  console.log('skus:', skus);

  const { sfpPlatforms, productGenerations, productTypes, brandFamilies, productLines, designMarkets } = useAppContext();

  const getSkusHelper = async () => {
    setIsLoading(true);
    const resp = await getSkus();
    if (resp) {
      const formattedSkus = formatData(resp);
      setSkus(formattedSkus);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (sfpPlatforms && productGenerations && productTypes && brandFamilies && productLines && designMarkets) {
      getSkusHelper();
    }
  }, [sfpPlatforms, productGenerations, productTypes, brandFamilies, productLines, designMarkets]);

  const formatData = (skusData: SKU[]): TableData[] => {
    return skusData.map((sku) => {
      return {
        ...sku,
        id: sku._id as string,
        sfpPlatformId: sfpPlatforms?.find((sfp) => sfp._id === sku.sfpPlatformId)?.name || '-',
        productGenerationId: productGenerations?.find((pg) => pg._id === sku.productGenerationId)?.name || '-',
        productTypeId: productTypes?.find((pt) => pt._id === sku.productTypeId)?.name || '-',
        brandFamilyId: brandFamilies?.find((bf) => bf._id === sku.brandFamilyId)?.name || '-',
        productLineId: productLines?.find((pl) => pl._id === sku.productLineId)?.name || '-',
        designMarketId: designMarkets?.find((dm) => dm._id === sku.designMarketId)?.name || '-',
        listingStatus: isSkuActive(sku) ? 'Active' : 'Inactive',
      };
    });
  };

  return (
    <>
      <Actions
        breadcrumbs={[{ label: 'Portfolio' }]}
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: '',
            Icon: Refresh,
            onClick: () => getSkusHelper(),
          },
          {
            type: 'button',
            variant: 'contained',
            label: 'Add SKU',
            Icon: AddIcon,
            onClick: () => navigate('/portfolio/add-sku'),
          },
          {
            type: 'button',
            variant: 'contained',
            label: 'Upload CSV',
            Icon: UploadIcon,
            onClick: () => navigate('/portfolio/upload-csv'),
          },
          {
            type: 'input',
            variant: 'outlined',
            label: 'Search',
            Icon: SearchIcon,
            isheader: 'true',
            onClick: () => {},
          },
        ]}
      />

      <DataTable
        isLoading={isLoading}
        height="75vh"
        columns={[
          { field: 'sfpPlatformId', headerName: 'SFP Platform', width: 200 },
          { field: 'productGenerationId', headerName: 'Product Generation', width: 200 },
          { field: 'productTypeId', headerName: 'Product Type', width: 200 },
          { field: 'brandFamilyId', headerName: 'Brand Family', width: 200 },
          { field: 'productLineId', headerName: 'Product Line', width: 200 },
          { field: 'skuName', headerName: 'Name (LAC Facing)', width: 200 },
          { field: 'designMarketId', headerName: 'Design Market', width: 200 },
          { field: 'listingStatus', headerName: 'Listing Status', width: 200 },
        ]}
        rows={skus}
      />
    </>
  );
};

export default Portfolio;
