import EditIcon from '@mui/icons-material/Edit';
import { InputAdornment, TextField } from '@mui/material';
import { FlavourTranslation } from 'api/components/schemas';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { uploadAsset } from 'utils/apiRequests/generic';

import { Actions } from 'components/Actions';
import { Columns } from 'components/Columns';
import { Filedrop } from 'components/Filedrop';

import theme from 'config/theme';

interface AddDeploymentSkuAccordionItemProps {
  productContent: FlavourTranslation;
  onConfirm: (flavourTranslation: FlavourTranslation) => void;
}

export const AddDeploymentSkuAccordionItem: React.FC<AddDeploymentSkuAccordionItemProps> = ({ productContent, onConfirm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [flavourTranslation, setFlavourTranslation] = useState<FlavourTranslation>({ ...productContent });
  const [packFrontFile, setPackFrontFile] = useState<File | null>(null);
  const [packTopFile, setPackTopFile] = useState<File | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  async function createFileFromUrl(url: string, fileName: string, fileType: 'packFront' | 'packTop') {
    try {
      const response = await fetch(url);
      const data = await response.blob();

      if (fileType === 'packFront') setPackFrontFile(new File([data], fileName));
      if (fileType === 'packTop') setPackTopFile(new File([data], fileName));
    } catch (error) {
      enqueueSnackbar('Error downloading asset', { variant: 'error' });
    }
  }

  useEffect(() => {
    setFlavourTranslation({ ...productContent });

    if (productContent.packFrontUrl && productContent.packTopUrl) {
      createFileFromUrl(productContent.packFrontUrl, productContent.packFrontId, 'packFront');
      createFileFromUrl(productContent.packTopUrl, productContent.packTopId, 'packTop');
    }

    setPackFrontFile(null);
    setPackTopFile(null);
  }, [productContent]);

  const onValueChange = (field: keyof FlavourTranslation, value: string) => {
    setFlavourTranslation({ ...flavourTranslation, [field]: value });
  };

  const handleUploadImage = async (file: File) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append('file', file);

    const fileName = file.name;
    formData.append('name', fileName);

    const resp = await uploadAsset(formData);
    if (resp) {
      const assetId = resp._id;
      const imageUrl = resp.url;

      return { assetId, imageUrl };
    } else {
      enqueueSnackbar('Error uploading asset', { variant: 'error' });
      setIsLoading(false);
      return null;
    }
  };

  const onConfirmHelper = async () => {
    if (
      (!flavourTranslation.disclaimer && !productContent.disclaimer) ||
      (!flavourTranslation.longDescription && !productContent.longDescription) ||
      (!flavourTranslation.productName && !productContent.productName) ||
      (!flavourTranslation.shortDescription && !productContent.shortDescription)
    ) {
      enqueueSnackbar('Please fill in all fields', { variant: 'error' });
      return;
    }
    if (!packFrontFile || !packTopFile) {
      enqueueSnackbar('Please upload both pack front and pack top images', { variant: 'error' });
      return;
    }

    if (packFrontFile && packTopFile) {
      const packFrontImgResp = await handleUploadImage(packFrontFile);
      const packTopImgResp = await handleUploadImage(packTopFile);

      if (!packFrontImgResp || !packTopImgResp) {
        enqueueSnackbar('Error uploading images', { variant: 'error' });
        return;
      }
      const newTranslationResp: FlavourTranslation = {
        ...flavourTranslation,
        packFrontId: packFrontImgResp.assetId,
        packFrontUrl: packFrontImgResp.imageUrl,
        packTopId: packTopImgResp.assetId,
        packTopUrl: packTopImgResp.imageUrl,
      };

      setIsLoading(false);
      onConfirm(newTranslationResp);
    } else {
      onConfirm(flavourTranslation);
      setIsLoading(false);
    }
  };

  console.log('packFrontFile:', packFrontFile);

  return (
    <>
      <Columns>
        <div style={{ display: 'grid', rowGap: theme.spacing(2) }}>
          <TextField
            rows={2}
            multiline
            size="small"
            label="Short Description"
            required
            value={flavourTranslation?.shortDescription}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ height: '100%', display: 'flex', alignSelf: 'center' }}>
                  <EditIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => onValueChange('shortDescription', e.target.value)}
          />

          <TextField
            rows={3}
            multiline
            size="small"
            label="Long Description"
            required
            value={flavourTranslation?.longDescription}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ height: '100%', display: 'flex', alignSelf: 'center' }}>
                  <EditIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => onValueChange('longDescription', e.target.value)}
          />
        </div>

        <div style={{ display: 'grid', rowGap: theme.spacing(2) }}>
          <TextField
            size="small"
            label="Disclaimer"
            required
            multiline={true}
            value={flavourTranslation?.disclaimer}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ height: '100%', display: 'flex', alignSelf: 'flex-start' }}>
                  <EditIcon />
                </InputAdornment>
              ),
            }}
            rows={4}
            onChange={(e) => onValueChange('disclaimer', e.target.value)}
          />

          <TextField
            rows={4}
            size="small"
            label="Name"
            required
            value={flavourTranslation?.productName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ height: '100%', display: 'flex', alignSelf: 'center' }}>
                  <EditIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => onValueChange('productName', e.target.value)}
          />
        </div>
      </Columns>

      <div
        style={{
          marginTop: theme.spacing(2),
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridColumnGap: theme.spacing(4),
        }}>
        <>
          <Filedrop setFile={setPackFrontFile} file={packFrontFile} label="Pack Front" />
          <Filedrop setFile={setPackTopFile} file={packTopFile} label="Pack Top" />
        </>
      </div>

      <Actions
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Save',
            isLoading,
            onClick: onConfirmHelper,
          },
          {
            type: 'button',
            variant: 'outlined',
            label: 'Discard Changes',
            onClick: () => {
              setFlavourTranslation({ ...productContent });
              setPackFrontFile(null);
              setPackTopFile(null);
            },
          },
        ]}
      />
    </>
  );
};
