import { ArrowBackIosOutlined, ArrowForwardIosOutlined, RemoveCircle } from '@mui/icons-material';
import { IconButton, Tab } from '@mui/material';
import React from 'react';

interface CustomTabProps {
  label: string;
  onMoveLeft: () => void;
  onMoveRight: () => void;
  onSelect: () => void;
  onDelete?: () => void;
}

export const CustomTab: React.FC<CustomTabProps> = ({ label, onMoveLeft, onMoveRight, onSelect, onDelete }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '16px 24px',
      rowGap: '32px',
      marginRight: 20,
    }}>
    <IconButton
      style={{ backgroundColor: '#ebebeb', borderRadius: 50 }}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onMoveLeft();
      }}>
      <ArrowBackIosOutlined sx={{ fontSize: 20 }} />
    </IconButton>
    <Tab
      style={{ padding: 0, marginLeft: 5, marginRight: 5 }}
      label={label}
      onClick={(e) => {
        e.stopPropagation();
        onSelect();
      }}
    />
    <IconButton
      style={{ backgroundColor: '#ebebeb', borderRadius: 50, marginRight: 10 }}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onMoveRight();
      }}>
      <ArrowForwardIosOutlined sx={{ fontSize: 20 }} />
    </IconButton>
    {onDelete && (
      <IconButton
        style={{ backgroundColor: '#ebebeb', borderRadius: 50 }}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}>
        <RemoveCircle sx={{ fontSize: 20, color: 'red' }} />
      </IconButton>
    )}
  </div>
);

export default CustomTab;
