import styled from '@emotion/styled';

import theme from 'config/theme';

export const Container = styled.div<{ hasExtra: boolean }>`
  display: grid;
  margin: ${theme.spacing(2)} 0;
  grid-template-columns: ${(props) => (props.hasExtra ? '4fr 1fr' : '1fr')};
  align-items: center;
  column-gap: ${theme.spacing(4)};

  span {
    text-decoration: underline;
  }
`;
