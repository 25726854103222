import { Attributes, BrandFamily, IqosTravelData, ProductGeneration, ProductLine, ProductType, SfpPlatform } from 'api/components/schemas';
import axios from 'axios';
import { getAccessToken, handleApiError } from 'utils/helpers';

export const getSfpPlatforms = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/sfp_platforms`, { headers: { 'api-key': accessToken } });

    return resp.data as SfpPlatform[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};

export const getProductGenerations = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/product_generations`, { headers: { 'api-key': accessToken } });

    return resp.data as ProductGeneration[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};

export const getProductTypes = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/product_types`, { headers: { 'api-key': accessToken } });

    return resp.data as ProductType[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);

    return null;
  }
};
export const getBrandFamilies = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/brand_families`, { headers: { 'api-key': accessToken } });

    return resp.data as BrandFamily[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
export const getProductLines = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/product_lines`, { headers: { 'api-key': accessToken } });

    return resp.data as ProductLine[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
export const getIqosTravelData = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/iqos_travel_data`, { headers: { 'api-key': accessToken } });

    return resp.data as IqosTravelData[];
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
export const uploadAsset = async (formData: FormData) => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.post(`${process.env.REACT_APP_HUB_API_URL}/asset`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const getAttributes = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/attributes`, { headers: { 'api-key': accessToken } });

    return resp.data as Attributes;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
