import { AddCircleOutline } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Tabs, TextField, Typography } from '@mui/material';
import AWS from 'aws-sdk';
import { Actions, Box, Columns, CustomTab, FullscreenConfirmation, Layout } from 'components';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from 'config/theme';

const AddInstance = () => {
  const navigate = useNavigate();
  const LANGUAGES = [
    { name: 'Arabic', code: 'ar', label: 'عَرَبِيّ' },
    { name: 'Traditional Chinese', code: 'zh-tw', label: '繁體中文' },
    { name: 'Simplified Chinese', code: 'zh-cn', label: '简体中文' },
    { name: 'Korean', code: 'ko', label: '한국인' },
    { name: 'Japanese', code: 'jp', label: '日本語' },
    { name: 'French', code: 'fr', label: 'Français' },
    { name: 'German', code: 'de', label: 'Deutsch' },
    { name: 'Serbian', code: 'sr', label: 'Srpski' },
    { name: 'English', code: 'en', label: 'English' },
    { name: 'Malay', code: 'ms', label: 'Malay' },
    { name: 'Italian', code: 'it', label: 'Italiano' },
    { name: 'Greek', code: 'el', label: 'ελληνικά' },
    { name: 'Spanish', code: 'es', label: 'Español' },
    { name: 'Czech', code: 'cz', label: 'čeština' },
    { name: 'Albanian', code: 'sq', label: 'Shqip' },
    { name: 'Polish', code: 'pl', label: 'Polski' },
  ];

  const location = useLocation();
  const instanceToEdit = location.state?.instance;

  const [saving, setSaving] = useState(false);

  const [selectedLanguageTab, setSelectedLanguageTab] = useState(0);
  const [selectedPlatformTab, setSelectedPlatformTab] = useState(0);
  const [selectedPlaylistContentTab, setSelectedPlaylistContentTab] = useState(0);

  const [confirmationData, setConfirmationData] = useState(undefined);

  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState(
    instanceToEdit || {
      instanceName: '',
      market: '',
      location: [],
      drafts: [],
      published: {},
      languages: [], // this is only here because it was built before the historic configs support was added, its moved into drafts/published on the click event
    },
  );
  const [enableErrors, setEnableErrors] = useState(false);

  const [markets, setMarkets] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetch('https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/getMarketsFromMongo')
      .then((response) => response.json())
      .then((data) => {
        setMarkets(data);
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  useEffect(() => {
    fetch('https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/getLocationsFromMongo')
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  // Move these to secure aws url
  AWS.config.update({
    accessKeyId: process.env.AWS_ACCESS_KEY,
    secretAccessKey: process.env.AWS_SECRET,
    region: process.env.AWS_REGION_ID,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // return if not a letter, number of hyphen
    const regex = /^[A-Za-z0-9-]*$/;
    if (!regex.test(value)) {
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (instanceToEdit) {
      formValues.languages.forEach((language, index) => {
        /* Attractor video content url */
        if (instanceToEdit.languages[index].attractorSettings.attractorVideoContentUrl) {
          language.attractorSettings.attractorVideoContentUrl = instanceToEdit.languages[index].attractorSettings.attractorVideoContentUrl;
        }

        /* Popup image */
        if (instanceToEdit.languages[index].popupSettings.popupImageContentUrl) {
          language.popupSettings.popupImageContentUrl = instanceToEdit.languages[index].popupSettings.popupImageContentUrl;
        }

        // Add the content urls if they exist in the instance to edit
        language.platforms.forEach((platform, platformIndex) => {
          /* Platform Logo content url */
          if (instanceToEdit.languages[index].platforms[platformIndex].platformContent.platformLogoContentUrl) {
            platform.platformContent.platformLogoContentUrl =
              instanceToEdit.languages[index].platforms[platformIndex].platformContent.platformLogoContentUrl;
          }

          /* Background Image content url */
          if (instanceToEdit.languages[index].platforms[platformIndex].platformContent.backgroundImageContentUrl) {
            platform.platformContent.backgroundImageContentUrl =
              instanceToEdit.languages[index].platforms[platformIndex].platformContent.backgroundImageContentUrl;
          }

          if (platform.playlistContent) {
            platform.playlistContent.forEach((content, contentIndex) => {
              /* Content Upload content url */
              if (instanceToEdit.languages[index].platforms[platformIndex].playlistContent[contentIndex].contentUploadContentUrl) {
                content.contentUploadContentUrl =
                  instanceToEdit.languages[index].platforms[platformIndex].playlistContent[contentIndex].contentUploadContentUrl;
              }

              /* Subtitle content url */
              if (instanceToEdit.languages[index].platforms[platformIndex].playlistContent[contentIndex].subtitleContentUrl) {
                content.subtitleContentUrl =
                  instanceToEdit.languages[index].platforms[platformIndex].playlistContent[contentIndex].subtitleContentUrl;
              }
            });
          }
        });
      });
    }
  }, []);

  const handleUpdateLanguageFirstChild = (e, key) => {
    const { name, value } = e.target;
    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab] = {
      ...newValues.languages[selectedLanguageTab],
      [key]: {
        ...newValues.languages[selectedLanguageTab][key],
        [name]: value,
      },
    };

    setFormValues(newValues);
  };

  const handleUpdatePlatformFirstChild = (e, key) => {
    const { name, value } = e.target;
    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab] = {
      ...newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab],
      [key]: {
        ...newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab][key],
        [name]: value,
      },
    };

    setFormValues(newValues);
  };

  const handleUpdatePlaylistContentFirstChild = (e) => {
    let { name, value } = e.target;

    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent[selectedPlaylistContentTab] = {
      ...newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent[selectedPlaylistContentTab],
      [name]: value,
    };

    setFormValues(newValues);
  };

  const setAttractorVideo = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdateLanguageFirstChild({ target: { name: key, value: file } }, 'attractorSettings');
    }
  };

  const setPopupImage = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdateLanguageFirstChild({ target: { name: key, value: file } }, 'popupSettings');
    }
  };

  const setPlatformContent = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdatePlatformFirstChild({ target: { name: key, value: file } }, 'platformContent');
    }
  };

  const setPlaylistContentContent = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdatePlaylistContentFirstChild({ target: { name: key, value: file } }, 'playlistContent');
    }
  };

  const handleUpdateTopLevelKey = (key, value) => {
    let newValues = { ...formValues };
    newValues[key] = value;
    setFormValues(newValues);
  };

  const handleUpdatePlatformSkip = (key, data) => {
    let newValues = { ...formValues };

    newValues.languages[selectedLanguageTab] = {
      ...newValues.languages[selectedLanguageTab],
      [key]: {
        ...newValues.languages[selectedLanguageTab][key],
        skipPlatforms: data?.value,
      },
    };

    setFormValues(newValues);
  };

  const handleLocationChange = (locations) => {
    let newValues = { ...formValues };
    newValues.location = locations;
    setFormValues(newValues);
  };

  const handleLanguageChange = (languages) => {
    let newValues = { ...formValues };
    newValues.languages = languages;

    // add first platform if new
    newValues.languages.forEach((language, index) => {
      if (language.platforms === undefined) {
        newValues.languages[index].platforms = [
          {
            platformKey: 'platform_1',
            platformName: 'Platform 1',
            playlistContent: [
              {
                contentKey: 'content_1',
                contentName: 'Content 1',
                popup: false,
              },
            ],
          },
        ];
      }
    });

    setFormValues(newValues);

    // reset the tabs
    setSelectedPlatformTab(0);
    setSelectedPlaylistContentTab(null);
  };

  const handleAddPlatform = (language) => {
    let newValues = { ...formValues };
    let newIndex = newValues.languages[selectedLanguageTab].platforms.length + 1;
    newValues.languages[selectedLanguageTab].platforms.push({
      platformKey: `platform_${newIndex}`,
      platformName: `Platform ${newIndex}`,
      playlistContent: [
        {
          contentKey: 'content_1',
          contentName: 'Content 1',
          popup: false,
        },
      ],
    });
    setSelectedPlatformTab(newIndex - 1);
    setSelectedPlaylistContentTab(null);
    setFormValues(newValues);
  };

  const handleAddPlaylistContent = (content) => {
    let newValues = { ...formValues };
    let newIndex = newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent.length + 1;
    newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent.push({
      contentKey: `content_${newIndex}`,
      contentName: `Content ${newIndex}`,
      popup: false,
    });
    setSelectedPlaylistContentTab(newIndex - 1);
    setFormValues(newValues);
  };

  const handleSubmitDraft = async (e) => {
    e.preventDefault();
    setSaving(true);
    setEnableErrors(true);
    console.log('TM: Draft 1: ', formValues);
    console.log('TM: Stringified Draft 1: ', JSON.stringify(formValues));

    // Global Settings Validation
    if (!formValues?.instanceName) {
      setSaving(false);
      return;
    }
    if (!formValues?.market) {
      setSaving(false);
      return;
    }
    if (formValues?.location?.length <= 0) {
      setSaving(false);
      return;
    }
    if (formValues?.languages?.length <= 0) {
      setSaving(false);
      return;
    }

    // General Settings Validation
    let errorField = '';

    let formValid = true;
    formValues?.languages?.forEach((language) => {
      // General Settings Validation
      if (language?.generalSettings) {
        if (!language?.generalSettings?.healthWarning) {
          formValid = false;
          errorField = 'General Settings - Health Warning';
          return;
        }
        if (!language?.generalSettings?.inactiveQuestion) {
          formValid = false;
          errorField = 'General Settings - Inactive Question';
          return;
        }
        if (!language?.generalSettings?.back) {
          formValid = false;
          errorField = 'General Settings - Back';
          return;
        }
        if (!language?.generalSettings?.next) {
          formValid = false;
          errorField = 'General Settings - Next';
          return;
        }
        if (!language?.generalSettings?.previous) {
          formValid = false;
          errorField = 'General Settings - Previous';
          return;
        }
      } else {
        formValid = false;
        errorField = 'General Settings';
        return;
      }

      // Attractor Settings Validation
      if (language?.attractorSettings) {
        if (!language?.attractorSettings?.yes) {
          formValid = false;
          errorField = 'Attractor Settings - Yes';
          return;
        }
        if (!language?.attractorSettings?.no) {
          formValid = false;
          errorField = 'Attractor Settings - No';
          return;
        }
        if (!language?.attractorSettings?.consentQuestion) {
          formValid = false;
          errorField = 'Attractor Settings - Consent Question';
          return;
        }
        if (!language?.attractorSettings?.nonLacMessage) {
          formValid = false;
          errorField = 'Attractor Settings - Non LAC Message';
          return;
        }
      } else {
        formValid = false;
        errorField = 'Attractor Settings';
        return;
      }

      // Popup Settings Validation
      if (language?.popupSettings) {
        if (!language?.popupSettings?.title) {
          formValid = false;
          errorField = 'Popup Settings - Title';
          return;
        }
        if (!language?.popupSettings?.yes) {
          formValid = false;
          errorField = 'Popup Settings - Yes';
          return;
        }
        if (!language?.popupSettings?.no) {
          formValid = false;
          errorField = 'Popup Settings - No';
          return;
        }
      } else {
        formValid = false;
        errorField = 'Popup Settings';
        return;
      }

      // Platform Sidebar Settings Validation
      if (language?.platformSidebar) {
        if (!language?.platformSidebar?.mainParagraph) {
          formValid = false;
          errorField = 'Platform Sidebar - Main Paragraph';
          return;
        }
        if (!language?.platformSidebar?.title) {
          formValid = false;
          errorField = 'Platform Sidebar - Title';
          return;
        }
        if (!language?.platformSidebar?.subtitle) {
          formValid = false;
          errorField = 'Platform Sidebar - Subtitle';
          return;
        }
        if (!language?.platformSidebar?.disclaimer) {
          formValid = false;
          errorField = 'Platform Sidebar - Disclaimer';
          return;
        }
      } else {
        formValid = false;
        errorField = 'Platform Sidebar';
        return;
      }

      // Platform Content
      language?.platforms?.forEach((platform) => {
        if (platform?.platformContent) {
          if (!platform?.platformContent?.categoryName) {
            formValid = false;
            errorField = `Platform Content - ${platform?.platformContent?.categoryName} - Category Name`;
            return;
          }
          if (!platform?.platformContent?.description) {
            formValid = false;
            errorField = `Platform Content - ${platform?.platformContent?.categoryName} - Description`;
            return;
          }
          if (!platform?.platformContent?.ctaButton) {
            formValid = false;
            errorField = `Platform Content - ${platform?.platformContent?.categoryName} - CTA Button`;
            return;
          }
        } else {
          formValid = false;
          errorField = `Platform Content - ${platform?.platformContent?.categoryName}`;
          return;
        }

        // Playlist Content
        platform?.playlistContent?.forEach((content) => {
          if (!content?.contentTitle) {
            formValid = false;
            errorField = `Playlist Content - Content Title`;
            return;
          }
        });
      });
    });

    // return if form is not valid
    if (!formValid) {
      setSaving(false);
      enqueueSnackbar(`Missing Fields: ${errorField}`, { variant: 'error' });
      return;
    }

    var formattedValues = { ...formValues };

    const fetchExistingInstance = await fetch(
      `https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/getConfigByInstanceId?instanceId=${formattedValues.instanceName}`,
    );
    const { response } = await fetchExistingInstance.json();

    /* if (!response) {
      enqueueSnackbar(`Server error please try again later.`, { variant: 'error' });
      return;
    } */

    const existingInstance = response;

    var draftIndex;

    if (instanceToEdit === undefined && existingInstance === undefined) {
      // new instance
      draftIndex = 1;
    } else {
      draftIndex = existingInstance?.drafts?.length + 1;
    }

    if (existingInstance === undefined) {
      // Undefined, so this is a new instance
      formattedValues.drafts = [
        {
          version: draftIndex,
          languages: formattedValues?.languages,
        },
      ];
    } else {
      // Existing, so this is a new draft
      formattedValues.drafts = [
        ...existingInstance?.drafts,
        {
          version: draftIndex,
          languages: formattedValues?.languages,
        },
      ];

      formattedValues.published = existingInstance?.published;
    }

    console.log('TM: Formatted Values 2: ', formattedValues);
    console.log('TM: Stringified Formatted Values 2: ', JSON.stringify(formattedValues));

    // add the content urls if editing an instance
    if (instanceToEdit) {
      formattedValues?.languages?.forEach((language, index) => {
        /* Attractor video content url */
        if (instanceToEdit?.languages[index]?.attractorSettings?.attractorVideoContentUrl) {
          language.attractorSettings.attractorVideoContentUrl = instanceToEdit?.languages[index]?.attractorSettings?.attractorVideoContentUrl;
        }

        /* popup image content url */
        if (instanceToEdit?.languages[index]?.popupSettings?.popupImageContentUrl) {
          language.popupSettings.popupImageContentUrl = instanceToEdit?.languages[index]?.popupSettings?.popupImageContentUrl;
        }

        // Add the content urls if they exist in the instance to edit
        language?.platforms?.forEach((platform, platformIndex) => {
          /* Platform Logo content url */
          if (instanceToEdit?.languages[index]?.platforms[platformIndex]?.platformContent?.platformLogoContentUrl) {
            platform.platformContent.platformLogoContentUrl =
              instanceToEdit?.languages[index]?.platforms[platformIndex]?.platformContent?.platformLogoContentUrl;
          }

          /* Background Image content url */
          if (instanceToEdit?.languages[index]?.platforms[platformIndex]?.platformContent?.backgroundImageContentUrl) {
            platform.platformContent.backgroundImageContentUrl =
              instanceToEdit?.languages[index]?.platforms[platformIndex]?.platformContent?.backgroundImageContentUrl;
          }

          if (platform?.playlistContent) {
            platform?.playlistContent?.forEach((content, contentIndex) => {
              /* Content Upload content url */
              if (instanceToEdit?.languages[index]?.platforms[platformIndex]?.playlistContent[contentIndex]?.contentUploadContentUrl) {
                content.contentUploadContentUrl =
                  instanceToEdit?.languages[index]?.platforms[platformIndex]?.playlistContent[contentIndex]?.contentUploadContentUrl;
              }

              /* Subtitle content url */
              if (instanceToEdit?.languages[index]?.platforms[platformIndex]?.playlistContent[contentIndex]?.subtitleContentUrl) {
                content.subtitleContentUrl =
                  instanceToEdit?.languages[index]?.platforms[platformIndex]?.playlistContent[contentIndex]?.subtitleContentUrl;
              }
            });
          }
        });
      });
    }

    console.log('TM: Formatted Values 3: ', formattedValues);
    console.log('TM: Stringified Formatted Values 3: ', JSON.stringify(formattedValues));

    try {
      formattedValues = await handleImageUploads(formattedValues, draftIndex);

      // delete the old languages object that is only used to build a new
      delete formattedValues?.languages;

      const response = await fetch('https://56r0ul4d57.execute-api.eu-west-2.amazonaws.com/default/storeConfigItem', {
        method: 'POST',
        body: JSON.stringify(formattedValues),
      });

      if (!response.ok) {
        enqueueSnackbar(`Server error saving files. You may have to re-upload content.`, { variant: 'error' });
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSaving(false);
      navigate('/digital-tools/multi-category');
      enqueueSnackbar(`${existingInstance ? 'New draft created' : 'New instance created'}`, { variant: 'success' });
      console.log('Success:', data);
    } catch (error) {
      setSaving(false);
      console.error('Error:', error);
    }
  };

  async function handleImageUploads(formValues, newDraftVersion) {
    // A helper function to process platforms
    async function processPlatforms(platforms) {
      return Promise.all(
        platforms.map(async (platform) => {
          if (platform?.platformContent?.platformLogo && isFile(platform?.platformContent?.platformLogo)) {
            platform.platformContent.platformLogoContentUrl = await uploadImage(platform?.platformContent?.platformLogo, newDraftVersion);
            platform.platformContent.platformLogoAssetName = getFilenameFromURL(platform?.platformContent?.platformLogoContentUrl);
          }

          if (platform?.platformContent?.backgroundImage && isFile(platform?.platformContent?.backgroundImage)) {
            platform.platformContent.backgroundImageContentUrl = await uploadImage(platform?.platformContent?.backgroundImage, newDraftVersion);
            platform.platformContent.backgroundImageAssetName = getFilenameFromURL(platform?.platformContent?.backgroundImageContentUrl);
          }

          if (platform?.playlistContent) {
            platform.playlistContent = await Promise.all(
              platform?.playlistContent?.map(async (content) => {
                if (content?.contentUpload && isFile(content?.contentUpload)) {
                  content.contentUploadContentUrl = await uploadImage(content?.contentUpload, newDraftVersion);
                  content.contentUploadAssetName = getFilenameFromURL(content?.contentUploadContentUrl);
                }
                if (content?.subtitle && isFile(content?.subtitle)) {
                  content.subtitleContentUrl = await uploadImage(content?.subtitle, newDraftVersion);
                  content.subtitleAssetName = getFilenameFromURL(content?.subtitleContentUrl);
                }
                return content;
              }),
            );
          }

          return platform;
        }),
      );
    }

    // Process each language
    await Promise.all(
      formValues?.languages?.map(async (language, languageIndex) => {
        if (language?.attractorSettings?.video && isFile(language?.attractorSettings?.video)) {
          language.attractorSettings.attractorVideoContentUrl = await uploadImage(language?.attractorSettings?.video, newDraftVersion);
          language.attractorSettings.attractorVideoAssetName = getFilenameFromURL(language?.attractorSettings?.attractorVideoContentUrl);
        }

        if (language?.popupSettings?.image && isFile(language?.popupSettings?.image)) {
          language.popupSettings.popupImageContentUrl = await uploadImage(language?.popupSettings?.image, newDraftVersion);
          language.popupSettings.popupImageAssetName = getFilenameFromURL(language?.popupSettings?.popupImageContentUrl);
        }

        if (language?.platforms) {
          language.platforms = await processPlatforms(language?.platforms);
        }
      }),
    );

    return formValues;
  }

  const uploadImage = (file, newDraftVersion) => {
    return new Promise((resolve, reject) => {
      const s3 = new AWS.S3();
      const params = {
        Bucket: 'multicat-media',
        Key: `${formValues.instanceName}/draft-${newDraftVersion}/${generateRandomGUID()}-${file.name}`,
        Body: file,
        ACL: 'public-read',
      };

      s3.upload(params, function (err, data) {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };

  const getFilenameFromURL = (url) => {
    const parts = url.split('/');
    if (parts.length > 5) {
      return parts[5];
    } else {
      return '';
    }
  };

  const generateRandomGUID = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const moveLanguageTab = (dragIndex, direction) => {
    const newPosition = dragIndex + direction;
    if (newPosition < 0 || newPosition >= formValues.languages.length) return;

    const newLanguages = [...formValues.languages];
    const [removedLanguage] = newLanguages.splice(dragIndex, 1);
    newLanguages.splice(newPosition, 0, removedLanguage);

    setFormValues({ ...formValues, languages: newLanguages });

    if (selectedLanguageTab === dragIndex) {
      setSelectedLanguageTab(newPosition);
    } else if (selectedLanguageTab === newPosition) {
      setSelectedLanguageTab(dragIndex > newPosition ? selectedLanguageTab + 1 : selectedLanguageTab - 1);
    }
  };

  const movePlatformTab = (dragIndex, direction) => {
    const newPosition = dragIndex + direction;
    if (newPosition < 0 || newPosition >= formValues.languages[selectedLanguageTab].platforms.length) return;

    const newPlatforms = [...formValues.languages[selectedLanguageTab].platforms];
    const [removedPlatform] = newPlatforms.splice(dragIndex, 1);
    newPlatforms.splice(newPosition, 0, removedPlatform);

    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms = newPlatforms;
    setFormValues(newValues);

    if (selectedPlatformTab === dragIndex) {
      setSelectedPlatformTab(newPosition);
    } else if (selectedPlatformTab === newPosition) {
      setSelectedPlatformTab(dragIndex > newPosition ? selectedPlatformTab + 1 : selectedPlatformTab - 1);
    }
  };

  const moveContentTab = (dragIndex, direction) => {
    const newPosition = dragIndex + direction;
    if (newPosition < 0 || newPosition >= formValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent.length) return;

    const newPlaylistContent = [...formValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent];
    const [removedContent] = newPlaylistContent.splice(dragIndex, 1);
    newPlaylistContent.splice(newPosition, 0, removedContent);

    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent = newPlaylistContent;
    setFormValues(newValues);

    if (selectedPlaylistContentTab === dragIndex) {
      setSelectedPlaylistContentTab(newPosition);
    } else if (selectedPlaylistContentTab === newPosition) {
      setSelectedPlaylistContentTab(dragIndex > newPosition ? selectedPlaylistContentTab + 1 : selectedPlaylistContentTab - 1);
    }
  };

  const handleDeletePlatform = (index) => {
    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms.splice(index, 1);
    setFormValues(newValues);
    setSelectedPlatformTab(0);
    setSelectedPlaylistContentTab(null);
  };

  const handleDeletePlaylistContent = (index) => {
    let newValues = { ...formValues };
    newValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent.splice(index, 1);
    setFormValues(newValues);
    setSelectedPlaylistContentTab(null);
  };

  const isFile = (input) => {
    return input instanceof File;
  };

  const showConfirmation = (title, body, confirmText, cancelText, onConfirm, onCancel) => {
    setConfirmationData({
      title: title,
      body: body,
      confirmText: confirmText,
      cancelText: cancelText,
      onConfirm: onConfirm,
      onCancel: onCancel,
    });
  };

  const handleNumberOnly = (event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <Layout>
      {confirmationData !== undefined && <FullscreenConfirmation confirmationData={confirmationData} />}
      <form
        onSubmit={handleSubmitDraft}
        style={{ height: confirmationData !== undefined && '85vh', overflow: confirmationData !== undefined && 'hidden' }}>
        <Columns>
          <Actions breadcrumbs={[{ label: 'Multi Category CMS', path: '/digital-tools/multi-category' }, { label: 'Add Instance' }]} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="error"
              style={{ margin: 20 }}
              onClick={() => {
                showConfirmation(
                  'Cancel changes',
                  'All unsaved changes will be lost.',
                  'Yes',
                  'No',
                  () => {
                    navigate('/digital-tools/multi-category');
                  },
                  () => {
                    setConfirmationData(undefined);
                  },
                );
              }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" style={{ margin: 20 }}>
              {saving ? <CircularProgress sx={{ color: 'white' }} /> : 'Save New Draft'}
            </Button>
          </div>
        </Columns>
        <div style={{ marginBottom: theme.spacing(4) }}>
          <Box>
            <Typography variant="h2">Global settings</Typography>
            <TextField
              name="instanceName"
              label="Instance Name"
              variant="outlined"
              value={formValues.instanceName}
              onChange={handleInputChange}
              error={enableErrors && formValues.instanceName.length <= 0}
              fullWidth
              disabled={instanceToEdit}
            />
            <Autocomplete
              options={markets}
              getOptionLabel={(option) => option.name}
              value={formValues.market || undefined}
              disabled={instanceToEdit}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Market"
                  variant="outlined"
                  error={enableErrors && formValues.market.length <= 0}
                  /* helperText={errors.market} */
                  margin="normal"
                />
              )}
              onChange={(_, data) => {
                handleUpdateTopLevelKey('market', data);
              }}
            />
            <Autocomplete
              multiple
              options={locations}
              getOptionLabel={(option) => option.name}
              value={formValues.location}
              disabled={instanceToEdit}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  variant="outlined"
                  error={enableErrors && formValues.location.length <= 0}
                  /* helperText={errors.location} */
                  margin="normal"
                />
              )}
              onChange={(_, data) => {
                handleLocationChange(data);
              }}
            />
            <Autocomplete
              multiple
              options={LANGUAGES}
              value={formValues.languages}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="outlined"
                  error={enableErrors && formValues.languages.length <= 0}
                  /* helperText={errors.languagesError} */
                  margin="normal"
                />
              )}
              onChange={(_, data) => {
                handleLanguageChange(data);
              }}
            />
          </Box>
        </div>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {formValues?.languages?.length >= 1 ? (
            <Tabs value={selectedLanguageTab}>
              {formValues.languages.map((language, index) => (
                <CustomTab
                  key={index}
                  label={language.name}
                  onSelect={() => {
                    setSelectedLanguageTab(index);
                    setSelectedPlaylistContentTab(null);
                  }}
                  onMoveLeft={() => moveLanguageTab(index, -1)}
                  onMoveRight={() => moveLanguageTab(index, 1)}
                />
              ))}
            </Tabs>
          ) : (
            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 16 }}>
              Please select at least 1 language
            </Typography>
          )}
        </div>
        {formValues?.languages?.map((language, languageIndex) => {
          return (
            <div key={languageIndex} role="tabpanel" hidden={selectedLanguageTab !== languageIndex}>
              {selectedLanguageTab === languageIndex && (
                <>
                  {/* GENERAL SETTINGS */}
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Box p={3}>
                      <Typography variant="h2">General settings</Typography>
                      <Columns>
                        <div>
                          <TextField
                            name="healthWarning"
                            label="Health Warning (disclaimer)"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.healthWarning}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.healthWarning}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="inactiveQuestion"
                            label="Inactive Question"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.inactiveQuestion}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.inactiveQuestion}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="inactiveConfirm"
                            label="Inactive Confirm"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.inactiveConfirm}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.inactiveConfirm}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                        </div>
                        <div>
                          <TextField
                            name="back"
                            label="Back"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.back}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.back}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="next"
                            label="Next"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.next}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.next}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="previous"
                            label="Previous"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].generalSettings?.previous}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].generalSettings?.previous}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'generalSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                        </div>
                      </Columns>
                    </Box>
                  </div>
                  {/* ATTRACTOR SCREEN SETTINGS */}
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Box p={3}>
                      <Typography variant="h2">Attractor screen settings</Typography>
                      <Columns>
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <input
                              type="file"
                              accept=".mp4"
                              onChange={(e) => setAttractorVideo(e, 'video')}
                              style={{ display: 'none' }}
                              id="video-upload"
                            />
                            <label htmlFor="video-upload" style={{ width: 300 }}>
                              <Button variant="contained" color="primary" component="span">
                                Upload MP4 File (Attractor Video)
                              </Button>
                            </label>
                            <TextField
                              name="video"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={
                                isFile(formValues.languages[selectedLanguageTab].attractorSettings?.video)
                                  ? formValues.languages[selectedLanguageTab].attractorSettings?.video?.name
                                  : instanceToEdit && instanceToEdit.languages[selectedLanguageTab]?.attractorSettings.attractorVideoContentUrl
                              }
                              placeholder="Attractor video"
                              InputLabelProps={{ shrink: true }}
                              style={{ marginLeft: 10 }}
                            />
                          </div>
                          <TextField
                            name="yes"
                            label="Yes"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].attractorSettings?.yes}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].attractorSettings?.yes}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'attractorSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="no"
                            label="No"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].attractorSettings?.no}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].attractorSettings?.no}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'attractorSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <Autocomplete
                            options={[
                              { name: 'True', value: true },
                              { name: 'False', value: false },
                            ]}
                            getOptionLabel={(option) => option.name}
                            value={
                              formValues.languages[selectedLanguageTab].attractorSettings?.skipPlatforms
                                ? { name: 'True', value: true }
                                : { name: 'False', value: false }
                            }
                            renderInput={(params) => <TextField {...params} label="Skip Platforms" variant="outlined" margin="normal" />}
                            onChange={(_, data) => {
                              handleUpdatePlatformSkip('attractorSettings', data);
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            name="consentQuestion"
                            label="Consent Question"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].attractorSettings?.consentQuestion}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].attractorSettings?.consentQuestion}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'attractorSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                            multiline
                            rows={5}
                          />
                          <TextField
                            name="nonLacMessage"
                            label="Non LAC Message"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].attractorSettings?.nonLacMessage}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].attractorSettings?.nonLacMessage}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'attractorSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                            multiline
                            rows={5}
                          />
                        </div>
                      </Columns>
                    </Box>
                  </div>
                  {/* POPUP SCREEN SETTINGS */}
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Box p={3}>
                      <Typography variant="h2">Content Popup settings</Typography>
                      <Columns>
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <input
                              type="file"
                              accept=".png,.jpg"
                              onChange={(e) => setPopupImage(e, 'image')}
                              style={{ display: 'none' }}
                              id="image-upload"
                            />
                            <label htmlFor="image-upload" style={{ width: 300 }}>
                              <Button variant="contained" color="primary" component="span">
                                Upload PNG/JPG File (Popup Image)
                              </Button>
                            </label>
                            <TextField
                              name="image"
                              variant="outlined"
                              fullWidth
                              disabled
                              value={
                                isFile(formValues.languages[selectedLanguageTab].popupSettings?.image)
                                  ? formValues.languages[selectedLanguageTab].popupSettings?.image?.name
                                  : instanceToEdit && instanceToEdit.languages[selectedLanguageTab]?.popupSettings.popupImageContentUrl
                              }
                              placeholder="Popup Image"
                              InputLabelProps={{ shrink: true }}
                              style={{ marginLeft: 20 }}
                            />
                          </div>

                          <TextField
                            name="yes"
                            label="Yes"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].popupSettings?.yes}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].popupSettings?.yes}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'popupSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="no"
                            label="No"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].popupSettings?.no}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].popupSettings?.no}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'popupSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                        </div>
                        <div>
                          <TextField
                            name="title"
                            label="Title"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].popupSettings?.title}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].popupSettings?.title}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'popupSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                            multiline
                            rows={5}
                          />
                          <TextField
                            name="duration"
                            label="Display Duration"
                            variant="outlined"
                            type="number"
                            onKeyDown={handleNumberOnly}
                            value={formValues.languages[selectedLanguageTab].popupSettings?.duration}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].popupSettings?.duration}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'popupSettings');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                        </div>
                      </Columns>
                    </Box>
                  </div>
                  {/* PLATFORM SIDEBAR SETTINGS */}
                  <div style={{ marginBottom: theme.spacing(4) }}>
                    <Box p={3}>
                      <Typography variant="h2">Platform sidebar settings</Typography>
                      <Columns>
                        <div>
                          <TextField
                            name="title"
                            label="Side Title"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].platformSidebar?.title}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].platformSidebar?.title}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'platformSidebar');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="subtitle"
                            label="Sub Title"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].platformSidebar?.subtitle}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].platformSidebar?.subtitle}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'platformSidebar');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                          <TextField
                            name="disclaimer"
                            label="Side Disclaimer"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].platformSidebar?.disclaimer}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].platformSidebar?.disclaimer}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'platformSidebar');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                          />
                        </div>
                        <div>
                          <TextField
                            name="mainParagraph"
                            label="Side Main Paragraph"
                            variant="outlined"
                            value={formValues.languages[selectedLanguageTab].platformSidebar?.mainParagraph}
                            error={enableErrors && !formValues.languages[selectedLanguageTab].platformSidebar?.mainParagraph}
                            onChange={(e) => {
                              handleUpdateLanguageFirstChild(e, 'platformSidebar');
                            }}
                            style={{ marginTop: 20 }}
                            fullWidth
                            multiline
                            rows={5}
                          />
                        </div>
                      </Columns>
                    </Box>
                  </div>

                  {/* PLATFORMS TABS */}
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Tabs value={selectedPlatformTab}>
                      {language.platforms.map((platform, index) => {
                        return (
                          <CustomTab
                            key={index}
                            label={platform?.platformContent?.categoryName || 'Add a platform headline'}
                            onSelect={() => {
                              setSelectedPlatformTab(index);
                              setSelectedPlaylistContentTab(null);
                            }}
                            onMoveLeft={() => movePlatformTab(index, -1)}
                            onMoveRight={() => movePlatformTab(index, 1)}
                            onDelete={
                              language.platforms.length >= 2
                                ? () => {
                                    showConfirmation(
                                      'Delete Platform',
                                      'This will delete the platform and all of its content, are you sure?',
                                      'Yes',
                                      'No',
                                      () => {
                                        handleDeletePlatform(index);
                                        setConfirmationData(undefined);
                                      },
                                      () => {
                                        setConfirmationData(undefined);
                                      },
                                    );
                                  }
                                : undefined
                            }
                          />
                        );
                      })}
                      <Button onClick={() => handleAddPlatform(language)}>
                        <AddCircleOutline />
                      </Button>
                    </Tabs>
                  </div>
                  {language.platforms.map((platform, platformIndex) => {
                    return (
                      <>
                        <div key={platformIndex} role="tabpanel" hidden={selectedPlatformTab !== platformIndex}>
                          {selectedPlatformTab === platformIndex && (
                            <>
                              {/* PLATFORM CONTENT SETTINGS */}
                              <div style={{ marginBottom: theme.spacing(4) }}>
                                <Box p={3}>
                                  <Typography variant="h2">Platform content</Typography>
                                  <Columns>
                                    <div>
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <input
                                          type="file"
                                          accept=".jpg,.png"
                                          onChange={(e) => setPlatformContent(e, 'platformLogo')}
                                          style={{ display: 'none' }}
                                          id="platform-logo-upload"
                                        />
                                        <label htmlFor="platform-logo-upload" style={{ width: 300 }}>
                                          <Button variant="contained" color="primary" component="span">
                                            Upload JPG/PNG File (Platform Logo)
                                          </Button>
                                        </label>
                                        <TextField
                                          name="platformLogo"
                                          variant="outlined"
                                          fullWidth
                                          disabled
                                          value={
                                            isFile(platform.platformContent?.platformLogo)
                                              ? platform.platformContent?.platformLogo?.name
                                              : platform.platformContent?.platformLogoContentUrl
                                          }
                                          placeholder="Platform Logo"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                      <TextField
                                        name="categoryName"
                                        label="Category name (headline)"
                                        variant="outlined"
                                        value={platform.platformContent?.categoryName}
                                        error={enableErrors && !platform.platformContent?.categoryName}
                                        onChange={(e) => {
                                          handleUpdatePlatformFirstChild(e, 'platformContent');
                                        }}
                                        style={{ marginTop: 20 }}
                                        fullWidth
                                      />
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                        <input
                                          type="file"
                                          accept=".jpg,.png"
                                          onChange={(e) => setPlatformContent(e, 'backgroundImage')}
                                          style={{ display: 'none' }}
                                          id="platform-background-image-upload"
                                        />
                                        <label htmlFor="platform-background-image-upload" style={{ width: 300 }}>
                                          <Button variant="contained" color="primary" component="span">
                                            Upload PNG/JPG File (Background Image)
                                          </Button>
                                        </label>
                                        <TextField
                                          name="backgroundImage"
                                          variant="outlined"
                                          fullWidth
                                          disabled
                                          value={
                                            isFile(platform.platformContent?.backgroundImage)
                                              ? platform.platformContent?.backgroundImage?.name
                                              : platform.platformContent?.backgroundImageContentUrl
                                          }
                                          placeholder="Background Image"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <TextField
                                        name="description"
                                        label="Description"
                                        variant="outlined"
                                        value={platform.platformContent?.description}
                                        error={enableErrors && !platform.platformContent?.description}
                                        onChange={(e) => {
                                          handleUpdatePlatformFirstChild(e, 'platformContent');
                                        }}
                                        style={{ marginTop: 20 }}
                                        fullWidth
                                        multiline
                                        rows={5}
                                      />
                                      <TextField
                                        name="ctaButton"
                                        label="CTA Button"
                                        variant="outlined"
                                        value={platform.platformContent?.ctaButton}
                                        error={enableErrors && !platform.platformContent?.ctaButton}
                                        onChange={(e) => {
                                          handleUpdatePlatformFirstChild(e, 'platformContent');
                                        }}
                                        style={{ marginTop: 20 }}
                                        fullWidth
                                        multiline
                                        rows={5}
                                      />
                                    </div>
                                  </Columns>
                                </Box>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}

                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Tabs value={selectedPlaylistContentTab}>
                      {formValues?.languages[selectedLanguageTab]?.platforms[selectedPlatformTab]?.playlistContent.map((content, index) => {
                        return (
                          <CustomTab
                            key={index}
                            label={content?.contentTitle || 'Add a title'}
                            onSelect={() => setSelectedPlaylistContentTab(index)}
                            onMoveLeft={() => moveContentTab(index, -1)}
                            onMoveRight={() => moveContentTab(index, 1)}
                            onDelete={
                              formValues.languages[selectedLanguageTab].platforms[selectedPlatformTab].playlistContent.length >= 2
                                ? () => {
                                    showConfirmation(
                                      'Delete Playlist Content',
                                      'This will delete all of the playlist items content, are you sure?',
                                      'Yes',
                                      'No',
                                      () => {
                                        handleDeletePlaylistContent(index);
                                        setConfirmationData(undefined);
                                      },
                                      () => {
                                        setConfirmationData(undefined);
                                      },
                                    );
                                  }
                                : undefined
                            }
                          />
                        );
                      })}
                      <Button onClick={() => handleAddPlaylistContent()}>
                        <AddCircleOutline />
                      </Button>
                    </Tabs>
                  </div>
                  {formValues?.languages[selectedLanguageTab]?.platforms[selectedPlatformTab]?.playlistContent.map((content, contentIndex) => {
                    return (
                      <>
                        <div key={contentIndex} role="tabpanel" hidden={selectedPlaylistContentTab !== contentIndex}>
                          {selectedPlaylistContentTab === contentIndex && (
                            <>
                              <div style={{ marginBottom: theme.spacing(4) }}>
                                <Box p={3}>
                                  <Typography variant="h2">Playlist content</Typography>
                                  <Columns>
                                    <div>
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                        <input
                                          type="file"
                                          accept=".mp4,.jpg,.png"
                                          onChange={(e) => setPlaylistContentContent(e, 'contentUpload')}
                                          style={{ display: 'none' }}
                                          id="platform-content-upload"
                                        />
                                        <label htmlFor="platform-content-upload" style={{ width: 300 }}>
                                          <Button variant="contained" color="primary" component="span">
                                            Upload MP4/PNG/JPG File (Content)
                                          </Button>
                                        </label>
                                        <TextField
                                          name="contentUpload"
                                          variant="outlined"
                                          fullWidth
                                          disabled
                                          value={isFile(content?.contentUpload) ? content?.contentUpload?.name : content?.contentUploadContentUrl}
                                          placeholder="Content upload"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                      <TextField
                                        name="contentTitle"
                                        label="Title"
                                        variant="outlined"
                                        value={content?.contentTitle}
                                        error={enableErrors && !content?.contentTitle}
                                        onChange={(e) => {
                                          handleUpdatePlaylistContentFirstChild(e);
                                        }}
                                        style={{ marginTop: 20 }}
                                        fullWidth
                                      />
                                      {isFile(content?.contentUpload)
                                        ? content?.contentUpload?.name?.includes('.mp4')
                                        : !content?.contentUploadContentUrl?.includes('.mp4') && (
                                            <TextField
                                              name="imageDuration"
                                              label="Image Duration (seconds)"
                                              variant="outlined"
                                              type="number"
                                              onKeyDown={handleNumberOnly}
                                              value={content?.imageDuration}
                                              error={enableErrors && !content?.imageDuration}
                                              onChange={(e) => {
                                                handleUpdatePlaylistContentFirstChild(e);
                                              }}
                                              style={{ marginTop: 20 }}
                                            />
                                          )}
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                        <input
                                          type="file"
                                          accept=".vtt"
                                          onChange={(e) => setPlaylistContentContent(e, 'subtitle')}
                                          style={{ display: 'none' }}
                                          id="platform-content-subtitle-upload"
                                        />
                                        <label htmlFor="platform-content-subtitle-upload" style={{ width: 300 }}>
                                          <Button variant="contained" color="primary" component="span">
                                            Upload VTT File (Subtitle)
                                          </Button>
                                        </label>
                                        <TextField
                                          name="subtitle"
                                          variant="outlined"
                                          fullWidth
                                          disabled
                                          value={isFile(content?.subtitle) ? content?.subtitle?.name : content?.subtitleContentUrl}
                                          placeholder="Subtitle"
                                          InputLabelProps={{ shrink: true }}
                                          style={{ marginLeft: 20 }}
                                        />
                                      </div>
                                    </div>
                                  </Columns>
                                </Box>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {selectedPlaylistContentTab === null && (
                    <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: 16 }}>
                      Please select a content tab
                    </Typography>
                  )}
                </>
              )}
            </div>
          );
        })}
      </form>
    </Layout>
  );
};

export default AddInstance;
