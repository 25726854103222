import { Alert, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SKU } from 'api/components/schemas';
import { Actions, Box, Columns, FormElement, Layout, MenuTabs, ProductAttributes } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSku } from 'utils/apiRequests/skus';
import { errorsInSku } from 'utils/helpers';

import theme from 'config/theme';

const AddSKU = () => {
  const navigate = useNavigate();

  const { sfpPlatforms, productGenerations, productTypes, brandFamilies, productLines, designMarkets } = useAppContext();

  const [error, setError] = useState<string | null>(null);
  const [newSku, setNewSku] = useState<SKU>({
    sfpPlatformId: '',
    brandFamilyId: '',
    designMarketId: '',
    locationLaunchDates: [],
    productGenerationId: '',
    globalDelistDate: new Date(),
    globalLaunchDate: new Date(),
    product: {
      category: 'warm',
      nicotine: 0,
      nicotineLevel: 0,
    },
    productTypeId: '',
    skuName: '',
    smdlCode: '',
    similarProductIds: [],
    productLineId: '',
  });

  const { enqueueSnackbar } = useSnackbar();

  const createSkuHelper = async () => {
    const error = errorsInSku(newSku);
    if (error) {
      setError(error);
    } else {
      setError(null);
    }

    const resp = await createSku(newSku);
    if (resp) {
      enqueueSnackbar('SKU created successfully', { variant: 'success' });
      navigate('/portfolio');
    } else {
      setError('Error creating SKU');
    }
  };

  const links = [
    { label: 'Portfolio', value: 'portfolio', navigateTo: '/portfolio' },
    { label: 'Availability', value: 'availability', navigateTo: '/portfolio?tab=availability' },
  ];

  const setValue = (obj: Omit<SKU, 'product'>, setFunc: (val: any) => any, key: keyof SKU, val: string) => {
    setFunc({
      ...obj,
      [key]: val,
    });
  };

  const selectedDesignMarket = newSku.designMarketId ? designMarkets?.find((mrk) => mrk._id === newSku.designMarketId) ?? null : null;
  const selectedSfpPlatform = newSku.sfpPlatformId ? sfpPlatforms?.find((plat) => plat._id === newSku.sfpPlatformId) ?? null : null;

  console.log('brandFamilies:', brandFamilies);

  const selectedProductType = productTypes?.find((type) => type._id === newSku.productTypeId);

  const brandFamiliesForSelectedProductType = brandFamilies?.filter((fam) => selectedProductType?.brandFamilyIds.includes(fam._id as string));

  return (
    <Layout>
      <MenuTabs
        value={'portfolio'}
        onChange={(_, newValue) => navigate(links.filter((link) => link.value === newValue)[0].navigateTo)}
        links={links}
      />

      <Actions breadcrumbs={[{ label: 'Portfolio', path: '/portfolio' }, { label: 'Add SKU' }]} />

      {error ? (
        <Alert severity="error" style={{ marginBottom: theme.spacing(2) }}>
          {error}
        </Alert>
      ) : (
        <></>
      )}

      <Columns>
        <div>
          <div style={{ marginBottom: theme.spacing(2) }}>
            <Box>
              <Typography variant="h2">Product Platform Attributes.</Typography>

              <FormElement
                id="skuName"
                type="text"
                label="SKU Name (LAC Facing)"
                required
                onChange={(val: string) => setValue(newSku, setNewSku, 'skuName', val)}
                value=""
              />

              <Columns>
                <FormElement
                  type="select"
                  id="sfpPlatform"
                  label="SFP Platform"
                  value={newSku.sfpPlatformId}
                  options={sfpPlatforms?.map((plat) => ({ id: plat._id as string, name: plat.name }))}
                  onChange={(val: string) => {
                    setValue(newSku, setNewSku, 'sfpPlatformId', val);
                  }}
                  required
                />

                <FormElement
                  type="select"
                  id="productGeneration"
                  label="Product Generation"
                  value={newSku.productGenerationId}
                  options={productGenerations?.map((gen) => ({ id: gen._id as string, name: gen.name }))}
                  onChange={(val: string) => setValue(newSku, setNewSku, 'productGenerationId', val)}
                  required
                />

                <FormElement
                  type="select"
                  id="productType"
                  label="Product Type"
                  value={newSku.productTypeId}
                  options={productTypes?.map((type) => ({ id: type._id as string, name: type.name }))}
                  onChange={(val: string) => setValue(newSku, setNewSku, 'productTypeId', val)}
                  required
                />

                <FormElement
                  type="select"
                  id="brandFamily"
                  label="Brand Family"
                  value={newSku.brandFamilyId}
                  options={brandFamiliesForSelectedProductType?.map((fam) => ({ id: fam._id as string, name: fam.name }))}
                  onChange={(val: string) => setValue(newSku, setNewSku, 'brandFamilyId', val)}
                  required
                />

                <FormElement
                  type="select"
                  id="productLine"
                  label="Product Line"
                  value={newSku.productLineId ?? ''}
                  options={productLines?.map((line) => ({ id: line._id as string, name: line.name }))}
                  onChange={(val: string) => setValue(newSku, setNewSku, 'productLineId', val)}
                />
              </Columns>
            </Box>
          </div>

          <div style={{ marginBottom: theme.spacing(4) }}>
            <Box>
              <Typography variant="h2">SKUs.</Typography>

              <Columns>
                <FormElement
                  id="smdlCodes"
                  type="text"
                  label="SMDL Code(s)"
                  onChange={(val: string) => setValue(newSku, setNewSku, 'smdlCode', val)}
                  value={newSku.smdlCode}
                />

                <FormElement
                  id="designMarkets"
                  type="select"
                  label="Design Market(s)"
                  value={newSku.designMarketId ?? ''}
                  options={designMarkets?.map((design) => ({ id: design._id as string, name: design.name }))}
                  onChange={(val: string) => setValue(newSku, setNewSku, 'designMarketId', val)}
                />
              </Columns>
            </Box>
          </div>

          <Actions
            ctas={[
              {
                type: 'button',
                variant: 'contained',
                label: 'Confirm',
                onClick: createSkuHelper,
              },
              {
                type: 'button',
                variant: 'outlined',
                label: 'Cancel',
                onClick: () => {},
              },
            ]}
          />
        </div>

        <div>
          <div style={{ marginBottom: theme.spacing(4) }}>
            <Box>
              <Typography variant="h2">Dates.</Typography>

              <Columns>
                <FormElement
                  type="datePicker"
                  label="Launch Date"
                  required
                  onChange={(date) => setNewSku((oldS) => ({ ...oldS, globalLaunchDate: new Date(date) }))}
                  value={new AdapterDayjs().date(newSku.globalLaunchDate)}
                />
                <FormElement
                  type="datePicker"
                  label="Delist Date"
                  required
                  onChange={(date) => setNewSku((oldS) => ({ ...oldS, globalDelistDate: new Date(date) }))}
                  value={new AdapterDayjs().date(newSku.globalDelistDate)}
                />
              </Columns>
            </Box>
          </div>

          <div style={{ marginBottom: theme.spacing(4) }}>
            <Box>
              <Typography variant="h2">Similar Product.</Typography>

              <FormElement type="select" id="similarProduct" label="Similar Product" required value="" onChange={() => {}} />
            </Box>
          </div>

          <Box>
            <Typography variant="h2">Platform/Type Specific Attributes.</Typography>
            {newSku.designMarketId && newSku.sfpPlatformId && selectedDesignMarket ? (
              <ProductAttributes setNewSku={setNewSku} selectedDesignMarket={selectedDesignMarket} selectedSfpPlatform={selectedSfpPlatform} />
            ) : (
              <Typography>Please select a design market and SFP platform first</Typography>
            )}
          </Box>
        </div>
      </Columns>
    </Layout>
  );
};

export default AddSKU;
