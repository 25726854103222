import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Actions, ActionsProps } from 'components';
import { Children } from 'types/generic';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: Children;
  actions: ActionsProps['ctas'];
  maxWidth: Breakpoint;
};

export const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, content, actions, maxWidth = 'sm' }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Actions ctas={actions} />
      </DialogActions>
    </Dialog>
  );
};
