import CircleIcon from '@mui/icons-material/Circle';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button } from '@mui/material';
import { IqosTravelData, SKU } from 'api/components/schemas';
import { DataTable, EditIqosContentModal, Layout, Loader, Notifications } from 'components';
import { useAppContext } from 'context/AppContext';
import { useEffect, useState } from 'react';
import { getSkusForIqosTravel } from 'utils/apiRequests/skus';

import theme from 'config/theme';

export interface IQOSTravelTableData {
  name: string;
  sfpPlatform: string;
  productGeneration: string;
  productType: string;
  brandFamily: string;
  productLine: string;
  restricted: string | null;
  unrestricted: string | null;
  skus: string[];
  iqosTravelData?: IqosTravelData;
}

interface ModalState {
  row?: IQOSTravelTableData;
  show: boolean;
}

const IQOSTravel = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [skus, setSkus] = useState<Record<string, SKU[]>>({});
  const [tableData, setTableData] = useState<IQOSTravelTableData[] | null>(null);
  const [modalState, setModalState] = useState<ModalState>({ show: false });

  const { brandFamilies, sfpPlatforms, productGenerations, productTypes, productLines } = useAppContext();

  const getSkusHelper = async () => {
    setIsLoading(true);
    const resp = await getSkusForIqosTravel();
    if (resp) {
      setSkus(resp);
      const formattedData = formatDataForTable(resp);
      setTableData(formattedData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSkusHelper();
  }, []);

  console.log('skus:', skus);

  const formatDataForTable = (skusRecord: Record<string, SKU[]>) => {
    const rows: IQOSTravelTableData[] = [];
    for (const [key, value] of Object.entries(skusRecord)) {
      const row = {
        name: value[0].skuName,
        id: key,
        sfpPlatform: sfpPlatforms?.find((platform) => platform._id === value[0].sfpPlatformId)?.name ?? '',
        productGeneration: productGenerations?.find((generation) => generation._id === value[0].productGenerationId)?.name ?? '',
        productType: productTypes?.find((type) => type._id === value[0].productTypeId)?.name ?? '',
        brandFamily: brandFamilies?.find((family) => family._id === value[0].brandFamilyId)?.name ?? '',
        productLine: productLines?.find((line) => line._id === value[0].productLineId)?.name ?? '',
        restricted: value[0]?.iqosTravelData?.colour ? value[0]?.iqosTravelData?.colour : null,
        unrestricted: value[0]?.iqosTravelData?.imageUrl ? value[0]?.iqosTravelData?.imageUrl : null,
        skus: value.map((sku) => sku._id ?? ''),
        iqosTravelData: value[0].iqosTravelData,
      };
      rows.push(row);
    }
    return rows;
  };

  const skusWithoutIqosData = tableData?.filter((row) => !row.iqosTravelData) ?? [];

  return (
    <Layout>
      {isLoading ? <Loader /> : <></>}
      <EditIqosContentModal onClose={() => setModalState({ show: false })} row={modalState?.row} show={modalState.show} />

      {skusWithoutIqosData.length > 0 ? (
        <Notifications
          severity="warning"
          message={
            <>
              '{skusWithoutIqosData[0].name}' has been created. Please update the content for this SKU{' '}
              <span onClick={() => setModalState({ show: true, row: skusWithoutIqosData[0] })}>here</span>
            </>
          }
          extra={skusWithoutIqosData.length - 1}
        />
      ) : (
        <></>
      )}

      <DataTable
        columns={[
          { headerName: 'Name (LAC Facing)', field: 'name', width: 200 },
          { headerName: 'SFP Platform', field: 'sfpPlatform', width: 200 },
          { headerName: 'Product Generation', field: 'productGeneration', width: 200 },
          { headerName: 'Product Type', field: 'productType', width: 200 },
          { headerName: 'Brand Family', field: 'brandFamily', width: 200 },
          { headerName: 'Product Line', field: 'productLine', width: 200 },
          {
            headerName: 'Restricted',
            field: 'restricted',
            width: 200,
            renderCell: (params) =>
              params.row.restricted ? (
                <CircleIcon style={{ fill: params.row.restricted, color: params.row.restricted }} />
              ) : (
                <WarningIcon style={{ fill: theme.palette.warning.main }} />
              ),
          },
          {
            headerName: 'Unrestricted',
            field: 'unrestricted',
            width: 200,
            renderCell: (params) =>
              params.row.unrestricted ? (
                // add material ui image with a url

                <Box
                  component="img"
                  sx={{
                    height: 25,
                    width: 25,
                    borderRadius: 25,
                  }}
                  alt="Your Image Description"
                  src={params.row.unrestricted}
                />
              ) : (
                <WarningIcon style={{ fill: theme.palette.warning.main }} />
              ),
          },
          {
            field: 'actions',
            headerName: ' ',
            width: 200,
            renderCell: (params) => (
              <Button variant="contained" onClick={() => setModalState({ show: true, row: params.row })}>
                View
              </Button>
            ),
          },
        ]}
        rows={tableData ?? []}
      />

      {/* <AccordionTable
        headings={[
          'Name (LAC Facing)',
          'SFP Platform',
          'Product Generation',
          'Product Type',
          'Brand Family',
          'Product Line',
          'Restricted',
          'Unrestricted',
        ]}
        accordions={[
          {
            content: (
              <>
                <div
                  style={{
                    marginTop: theme.spacing(2),
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridColumnGap: theme.spacing(4),
                  }}>
                  <Filedrop file={file} setFile={setFile} label="Unrestricted image" />

                  <div>
                    <InputLabel shrink>Restricted color</InputLabel>
                    <FormElement type="color" label="HEX code" value={color} onChange={setColor} />
                  </div>
                </div>

                <Actions
                  ctas={[
                    {
                      type: 'button',
                      variant: 'contained',
                      label: 'Save',
                      onClick: () => {},
                    },
                    {
                      type: 'button',
                      variant: 'outlined',
                      label: 'Discard Changes',
                      onClick: () => {},
                    },
                  ]}
                />
              </>
            ),
            data: [
              'TEREA AMBER',
              'Heated Tobacco',
              '4.0 SmartCore Induction',
              'Heatstick',
              'TEREA',
              'Core',
              <CircleIcon style={{ fill: '#712cfe' }} />,
              <WarningIcon style={{ fill: theme.palette.warning.main }} />,
            ],
          },
          {
            content: (
              <>
                <div
                  style={{
                    marginTop: theme.spacing(2),
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridColumnGap: theme.spacing(4),
                  }}>
                  <Filedrop file={file} setFile={setFile} label="Unrestricted image" />

                  <div>
                    <InputLabel shrink>Restricted color</InputLabel>
                    <FormElement type="color" label="HEX code" value={color} onChange={setColor} />
                  </div>
                </div>

                <Actions
                  ctas={[
                    {
                      type: 'button',
                      variant: 'contained',
                      label: 'Save',
                      onClick: () => {},
                    },
                    {
                      type: 'button',
                      variant: 'outlined',
                      label: 'Discard Changes',
                      onClick: () => {},
                    },
                  ]}
                />
              </>
            ),
            data: [
              'TEREA BLUE',
              'Heated Tobacco',
              '4.0 SmartCore Induction',
              'Heatstick',
              'TEREA',
              'Core',
              <CircleIcon style={{ fill: theme.palette.success.main }} />,
              <WarningIcon style={{ fill: theme.palette.warning.main }} />,
            ],
          },
        ]}
      /> */}
    </Layout>
  );
};

export default IQOSTravel;
