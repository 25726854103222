import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Accordion, AccordionProps } from 'components';
import { createRef, useEffect, useRef, useState } from 'react';

import theme from 'config/theme';

type AccordionTableProps = {
  headings: Array<string>;
  accordions: Array<{
    content: AccordionProps['content'];
    data: Array<JSX.Element | string>;
    id: string;
  }>;
  onArrowPress: (direction: 'up' | 'down', id: string) => void;
};

export const AccordionTable: React.FC<AccordionTableProps> = ({ headings, accordions = [], onArrowPress }) => {
  const [colWidths, setColWidths] = useState<Array<number>>([]);
  const headingsRef = useRef(headings.map(() => createRef<HTMLDivElement>()));

  useEffect(() => {
    const nextWidths = headingsRef.current.map((ref) => ref.current?.getBoundingClientRect().width || 0);
    setColWidths(nextWidths);
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headings?.map((heading, countCol) => (
              <TableCell ref={headingsRef.current[countCol]} key={`col-${countCol}`}>
                {heading}
              </TableCell>
            ))}
            {<TableCell>Sort order</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody style={{ borderTopWidth: '1px', borderTopStyle: 'solid', borderTopColor: theme.palette.tertiary.main }}>
          {accordions.map((accordion, count) => (
            <tr key={`row-${count}`}>
              <td style={{ paddingTop: theme.spacing(2) }} colSpan={headings.length}>
                {/* {accordion} */}
                <Accordion content={accordion.content}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          {accordion.data.map((cell, countCell) => (
                            <TableCell key={`row-${count}-cell-${countCell}`} style={{ width: colWidths[countCell] }}>
                              {cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Accordion>
              </td>

              <td>
                <Box display="flex" justifyContent="space-between">
                  <IconButton disabled={count === 0} onClick={() => onArrowPress('up', accordion.id)}>
                    <ArrowUpward />
                  </IconButton>

                  <IconButton disabled={count === accordions.length - 1} onClick={() => onArrowPress('down', accordion.id)}>
                    <ArrowDownward />
                  </IconButton>
                </Box>
              </td>
            </tr>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
