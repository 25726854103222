import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';

type DataTableProps = {
  columns: GridColDef<any>[];
  rows: GridRowsProp;
  selectable?: boolean;
  selectedRows?: any[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
  isLoading?: boolean;
  height?: string;
};

export const DataTable: React.FC<DataTableProps> = ({ columns, rows, selectable, isLoading = false, setSelectedRows, height }) => {
  return (
    <Box sx={{ height: height ?? '800px', minWidth: '100%' }}>
      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        pageSizeOptions={[5, 25, 50, 100]}
        checkboxSelection={selectable}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          if (setSelectedRows) setSelectedRows(newRowSelectionModel);
        }}
        density="comfortable"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
      />
    </Box>
  );
};
