import styled from '@emotion/styled';

import theme from 'config/theme';

export const Container = styled.div`
  background: none;
  position: relative;

  .preview-overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .delete-icon {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      z-index: 1;

      svg {
        z-index: 3;
        width: 30px;
        height: 30px;
      }
    }
  }

  div.MuiBox-root {
    background: ${theme.palette.tertiary.light} !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  p.MuiTypography-root {
    font-size: 18px !important;
    font-weight: normal;
  }

  svg.MuiSvgIcon-root {
    color: ${theme.palette.tertiary.main} !important;
  }
`;
