import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button, Checkbox } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Location, LocationLaunchDate, SKU } from 'api/components/schemas';
import { Actions, AutocompleteProps, Box, DataTable, FormElement, Layout, Loader, MenuTabs } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSkus, updateSkus } from 'utils/apiRequests/skus';

import * as Styled from './styles';

interface FiltersType {
  sfpPlatform: string | null;
  productGeneration: string | null;
  productType: string | null;
  brandFamily: string | null;
  productLine: string | null;
}

interface TableData {
  _id: string;
  id: string; // for table
  sfpPlatform: string;
  productGeneration: string;
  productType: string;
  brandFamily: string;
  productLine: string;
  skuName: string;
  location: string;
  launchDate: Date;
  delistDate: Date;
  new: boolean;
  limitedEdition: boolean;
  dfExclusive: boolean;
  locationId: string;
}

const EditSKU = () => {
  const [selectedSku, setSelectedSku] = useState<AutocompleteProps>({ label: '', id: '' });
  const [filteredSkus, setFilteredSkus] = useState<SKU[] | null>(null);
  const [skusInLocation, setSkusInLocation] = useState<TableData[] | null>(null);
  const [skus, setSkus] = useState<SKU[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersType>({
    sfpPlatform: null,
    productGeneration: null,
    productType: null,
    brandFamily: null,
    productLine: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();
  const selectedLocations = location.state.selectedLocations as Location[];

  const { sfpPlatforms, productGenerations, productTypes, brandFamilies, productLines } = useAppContext();

  const links = [
    { label: 'Portfolio', value: 'portfolio', navigateTo: '/portfolio' },
    { label: 'Availability', value: 'availability', navigateTo: '/portfolio?tab=availability' },
  ];

  const getSkusHelper = async () => {
    setIsLoading(true);
    const locationIds = selectedLocations.map((loc) => loc._id);
    const skusResp = await getSkus();
    const skusInLocationResp = await getSkus(locationIds);

    if (skusResp) {
      setSkus(skusResp);
      setFilteredSkus(skusResp);
    }

    if (skusInLocationResp) {
      const formattedSkusForTable = formatSkusInLocationForTable(skusInLocationResp);
      setSkusInLocation(formattedSkusForTable);
    }
    setIsLoading(false);
  };

  const formatSkusInLocationForTable = (skusData: SKU[]): TableData[] => {
    let data: TableData[] = [];

    skusData.forEach((sku) => {
      sku.locationLaunchDates.forEach((loc) => {
        if (!selectedLocations.find((location) => location._id === loc.locationId)) return;

        data.push({
          _id: sku._id as string,
          id: `${sku._id}-${loc.locationId}`,
          sfpPlatform: sfpPlatforms?.find((plat) => plat._id === sku.sfpPlatformId)?.name ?? '',
          productGeneration: productGenerations?.find((gen) => gen._id === sku.productGenerationId)?.name ?? '',
          productType: productTypes?.find((typ) => typ._id === sku.productTypeId)?.name ?? '',
          brandFamily: brandFamilies?.find((brand) => brand._id === sku.brandFamilyId)?.name ?? '',
          productLine: productLines?.find((line) => line._id === sku.productLineId)?.name ?? '',
          location: selectedLocations.find((location) => location._id === loc.locationId)?.name ?? '',
          locationId: loc.locationId,
          skuName: sku.skuName,
          launchDate: loc.launchDate,
          delistDate: loc.endDate,
          new: loc.new,
          limitedEdition: loc.limitedEdition,
          dfExclusive: loc.dfExclusive,
        });
      });
    });

    console.log('data:', data);

    return data;
  };

  const filterSkus = (filteredSkusData: SKU[]) => {
    // filter skus based on filters
    let newFilteredSkus = [...filteredSkusData];

    // if all keys of filters are null, return all skus
    if (!filters.sfpPlatform && !filters.productGeneration && !filters.productType && !filters.brandFamily && !filters.productLine) {
      setFilteredSkus(skus);
      return;
    }

    if (filters.sfpPlatform) {
      newFilteredSkus = newFilteredSkus.filter((sku) => sku.sfpPlatformId === filters.sfpPlatform);
    }

    if (filters.productType) {
      newFilteredSkus = newFilteredSkus.filter((sku) => sku.productTypeId === filters.productType);
    }
    if (filters.productGeneration) {
      newFilteredSkus = newFilteredSkus.filter((sku) => sku.productGenerationId === filters.productGeneration);
    }
    if (filters.brandFamily) {
      newFilteredSkus = newFilteredSkus.filter((sku) => sku.brandFamilyId === filters.brandFamily);
    }
    if (filters.productLine) {
      newFilteredSkus = newFilteredSkus.filter((sku) => sku.productLineId === filters.productLine);
    }

    setFilteredSkus(newFilteredSkus);
  };

  const onAddSkuClick = () => {
    const skuToAdd = skus?.find((sku) => sku._id === selectedSku.id);
    if (!skuToAdd) return;

    const locationIds = selectedLocations.map((loc) => loc._id);

    // add location launch dates to skuToAdd
    const currLocationIds = skuToAdd.locationLaunchDates.map((loc) => loc.locationId);

    const locationIdsToAdd = locationIds.filter((locId) => !currLocationIds.includes(locId));

    const newLocationLaunchDates = locationIdsToAdd.map((locId) => ({
      locationId: locId,
      launchDate: new Date(),
      endDate: new Date(),
      new: false,
      limitedEdition: false,
      dfExclusive: false,
      marketId: selectedLocations.find((loc) => loc._id === locId)?.market?._id ?? '',
    }));

    skuToAdd.locationLaunchDates = [...skuToAdd.locationLaunchDates, ...newLocationLaunchDates];

    const formattedSkuForLocation = formatSkusInLocationForTable([skuToAdd]);
    console.log('formattedSkuForLocation:', formattedSkuForLocation);
    const newSkusInLocation = [...(skusInLocation ?? []), ...formattedSkuForLocation];
    setSkusInLocation(newSkusInLocation);
  };

  const onConfirmUploadSkuClick = () => {
    // turn skusInLocation into skus
    const newSkus: SKU[] = [];
    skusInLocation?.forEach((sku) => {
      const skuToAdd = newSkus.find((newSku) => newSku._id === sku._id);
      // if the sku is not already in new Skus:
      if (!skuToAdd) {
        const originalSku = skus?.find((s) => s._id === sku._id);
        if (!originalSku) return;

        const updatedOriginalSku = JSON.parse(JSON.stringify(originalSku)) as SKU;
        updatedOriginalSku.locationLaunchDates = updatedOriginalSku.locationLaunchDates.map((loc) => {
          if (loc.locationId === sku.locationId) {
            return {
              ...loc,
              launchDate: sku.launchDate,
              endDate: sku.delistDate,
              new: sku.new,
              limitedEdition: sku.limitedEdition,
              dfExclusive: sku.dfExclusive,
            };
          } else {
            return loc;
          }
        });

        newSkus.push(updatedOriginalSku);
      } else {
        // create the new location launch date
        const newLocationLaunchDate: LocationLaunchDate = {
          locationId: selectedLocations.find((loc) => loc.name === sku.location)?._id ?? '',
          launchDate: sku.launchDate,
          endDate: sku.delistDate,
          new: sku.new,
          limitedEdition: sku.limitedEdition,
          dfExclusive: sku.dfExclusive,
          marketId: selectedLocations.find((loc) => loc.name === sku.location)?.market?._id ?? '',
        };

        // find the index of the original SKU in newSkus
        const indexOfSkuToAdd = newSkus.findIndex((newSku) => newSku._id === sku._id);
        // add this launch date to locationLaunchDates
        if (indexOfSkuToAdd === -1) return;
        newSkus[indexOfSkuToAdd].locationLaunchDates.push(newLocationLaunchDate);
      }
    });

    updatedSkusHelper(newSkus);
  };

  const updatedSkusHelper = async (skus: SKU[]) => {
    setIsLoading(true);
    const resp = await updateSkus(skus);
    if (resp) {
      enqueueSnackbar('SKU updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Error updating SKU', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSkusHelper();
  }, []);

  useEffect(() => {
    if (filteredSkus) {
      filterSkus(filteredSkus);
    }
  }, [filters]);

  console.log('skusInLocation:', skusInLocation);

  return (
    <Layout>
      <MenuTabs
        value={'availability'}
        onChange={(_, newValue) => navigate(links.filter((link) => link.value === newValue)[0].navigateTo)}
        links={links}
      />
      {isLoading ? <Loader /> : <></>}

      <Actions breadcrumbs={[{ label: 'Availability', path: '/portfolio?tab=availability' }, { label: 'Edit SKU Availability Market(s)' }]} />

      <Styled.Add>
        <Box title="Add a new SKU for" titleVar={selectedLocations.map((loc) => `${loc.name}`).join(',')}>
          <Styled.Options>
            <FormElement
              type="select"
              id="sfpPlatform"
              label="SFP Platform"
              value={filters.sfpPlatform ?? ''}
              options={sfpPlatforms?.map((plat) => ({ id: plat._id as string, name: plat.name }))}
              onChange={(val: string) => {
                if (val === 'null') {
                  setFilters((oldS) => ({ ...oldS, sfpPlatform: null }));
                } else {
                  setFilters((oldS) => ({ ...oldS, sfpPlatform: val }));
                }
              }}
            />

            <FormElement
              type="select"
              id="productGeneration"
              label="Product Generation"
              value={filters.productGeneration ?? ''}
              options={productGenerations?.map((gen) => ({ id: gen._id as string, name: gen.name }))}
              onChange={(val: string) => {
                if (val === 'null') {
                  setFilters((oldS) => ({ ...oldS, productGeneration: null }));
                } else {
                  setFilters((oldS) => ({ ...oldS, productGeneration: val }));
                }
              }}
            />

            <FormElement
              type="select"
              id="productType"
              label="Product Type"
              value={filters.productType ?? ''}
              options={productTypes?.map((typ) => ({ id: typ._id as string, name: typ.name }))}
              onChange={(val: string) => {
                if (val === 'null') {
                  setFilters((oldS) => ({ ...oldS, productType: null }));
                } else {
                  setFilters((oldS) => ({ ...oldS, productType: val }));
                }
              }}
            />

            <FormElement
              type="select"
              id="brandFamily"
              label="Brand Family"
              value={filters.brandFamily ?? ''}
              options={brandFamilies?.map((brand) => ({ id: brand._id as string, name: brand.name }))}
              onChange={(val: string) => {
                if (val === 'null') {
                  setFilters((oldS) => ({ ...oldS, brandFamily: null }));
                } else {
                  setFilters((oldS) => ({ ...oldS, brandFamily: val }));
                }
              }}
            />

            <FormElement
              type="select"
              id="productLine"
              label="Product Line"
              value={filters.productLine ?? ''}
              options={productLines?.map((line) => ({ id: line._id as string, name: line.name }))}
              onChange={(val: string) => {
                if (val === 'null') {
                  setFilters((oldS) => ({ ...oldS, productLine: null }));
                } else {
                  setFilters((oldS) => ({ ...oldS, productLine: val }));
                }
              }}
            />
          </Styled.Options>

          <Styled.SKU>
            <FormElement
              id="sku"
              type="autocomplete"
              options={filteredSkus?.map((sku) => ({ id: sku?._id ?? '', name: sku.skuName }))}
              label="SKU"
              required
              onAutocompleteChange={(val) => setSelectedSku(val as AutocompleteProps)}
              value={selectedSku}
            />

            <Button disabled={!selectedSku?.id} onClick={onAddSkuClick} variant="contained" startIcon={<AddOutlinedIcon />}>
              Add SKU
            </Button>
          </Styled.SKU>
        </Box>
      </Styled.Add>

      <DataTable
        height="50vh"
        columns={[
          { field: 'sfpPlatform', headerName: 'SFP Platform', width: 160 },
          { field: 'productGeneration', headerName: 'Product Generation', width: 160 },
          { field: 'productType', headerName: 'Product Type', width: 160 },
          { field: 'brandFamily', headerName: 'Brand Family', width: 160 },
          { field: 'productLine', headerName: 'Product Line', width: 160 },
          { field: 'location', headerName: 'Location', width: 160 },
          { field: 'skuName', headerName: 'Name (LAC Facing)', width: 160 },
          {
            field: 'launchDate',
            headerName: 'Launch date',

            width: 200,
            renderCell: (params) => {
              return (
                <FormElement
                  type="datePicker"
                  label="Launch Date"
                  required
                  onChange={(date) => {
                    setSkusInLocation((oldS) => {
                      if (!oldS) return oldS;
                      let newState = [...oldS];
                      newState = newState.map((skuInLoc) => {
                        if (skuInLoc.id === params.id) {
                          return { ...skuInLoc, launchDate: new Date(date) };
                        }
                        return skuInLoc;
                      });
                      return newState;
                    });
                  }}
                  value={new AdapterDayjs().date(params.value as Date)}
                />
              );
            },
          },
          {
            field: 'delistDate',
            headerName: 'Delist date',

            width: 200,
            renderCell: (params) => {
              return (
                <FormElement
                  type="datePicker"
                  label="Delist Date"
                  required
                  onChange={(date) => {
                    setSkusInLocation((oldS) => {
                      if (!oldS) return oldS;
                      let newState = [...oldS];
                      newState = newState.map((skuInLoc) => {
                        if (skuInLoc.id === params.id) {
                          return { ...skuInLoc, delistDate: new Date(date) };
                        }
                        return skuInLoc;
                      });
                      return newState;
                    });
                  }}
                  value={new AdapterDayjs().date(params.value as Date)}
                />
              );
            },
          },
          {
            field: 'new',
            headerName: 'New',
            type: 'select',
            width: 130,
            renderCell: (params) => {
              return (
                <Checkbox
                  checked={params.value ? true : false}
                  onChange={(e) => {
                    setSkusInLocation((oldS) => {
                      if (!oldS) return oldS;
                      let newState = [...oldS];

                      newState = newState.map((skuInLoc) => {
                        if (skuInLoc.id === params.id) {
                          return { ...skuInLoc, new: e.target.checked };
                        }
                        return skuInLoc;
                      });

                      return newState;
                    });
                  }}
                />
              );
            },
          },
          {
            field: 'limitedEdition',
            headerName: 'Limited Edition',
            type: 'select',
            width: 160,
            renderCell: (params) => {
              return (
                <Checkbox
                  checked={params.value ? true : false}
                  onChange={(e) => {
                    setSkusInLocation((oldS) => {
                      if (!oldS) return oldS;
                      let newState = [...oldS];

                      newState = newState.map((skuInLoc) => {
                        if (skuInLoc.id === params.id) {
                          return { ...skuInLoc, limitedEdition: e.target.checked };
                        }
                        return skuInLoc;
                      });

                      return newState;
                    });
                  }}
                />
              );
            },
          },
          {
            field: 'dfExclusive',
            headerName: 'DF Exclusive',
            type: 'select',
            width: 160,
            renderCell: (params) => {
              return (
                <Checkbox
                  checked={params.value ? true : false}
                  onChange={(e) => {
                    setSkusInLocation((oldS) => {
                      if (!oldS) return oldS;
                      let newState = [...oldS];

                      newState = newState.map((skuInLoc) => {
                        if (skuInLoc.id === params.id) {
                          return { ...skuInLoc, dfExclusive: e.target.checked };
                        }
                        return skuInLoc;
                      });

                      return newState;
                    });
                  }}
                />
              );
            },
          },
        ]}
        rows={skusInLocation ?? []}
      />
      <Actions
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Confirm & Upload',
            onClick: onConfirmUploadSkuClick,
          },
          {
            type: 'button',
            variant: 'outlined',
            label: 'Cancel',
            onClick: () => {
              navigate('/portfolio?tab=availability');
            },
          },
        ]}
      />
    </Layout>
  );
};

export default EditSKU;
