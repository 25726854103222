import ArrowRight from '@mui/icons-material/ArrowRight';
import { Typography } from '@mui/material';
import { ShowMenuContext } from 'App';
import { ComponentType, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShowMenuContextProps } from 'types/generic';

import { MenuListChildProps } from 'components/Menu';

import theme from 'config/theme';

import * as Styled from './styles';

type MenuItemProps = {
  htmlKey?: string;
  label?: string;
  Icon: ComponentType;
  active?: boolean;
  onClick: () => void;
  isHeader?: boolean;
  showLabel?: boolean;
  children?: MenuListChildProps[];
  path?: string;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  htmlKey,
  label,
  Icon,
  active = false,
  onClick,
  isHeader = false,
  showLabel,
  children = [],
  path,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const showFullMenu = useContext(ShowMenuContext) as ShowMenuContextProps;

  useEffect(() => {
    !showFullMenu.value && setIsExpanded(false);
  }, [showFullMenu.value]);

  useEffect(() => {
    if (location.pathname.split('/')[1] === path?.split('/')[1] && showFullMenu.value) {
      setIsExpanded(true);
    }
  }, [location?.pathname, path, showFullMenu]);

  return (
    <Styled.Outer>
      <Styled.Container active={active} onClick={onClick} isHeader={isHeader}>
        <Styled.Icon color={isHeader ? theme.palette.secondary.main : active ? theme.palette.primary.main : theme.palette.tertiary.main}>
          <Icon />
        </Styled.Icon>

        {showLabel && (
          <Styled.Label>
            <Typography variant="caption" style={{ color: active ? theme.palette.primary.main : theme.palette.tertiary.main }}>
              {label}
            </Typography>
          </Styled.Label>
        )}

        {children.length > 0 && (
          <Styled.Toggle
            onClick={(e) => {
              e.stopPropagation();
              showFullMenu.set(true);
              setIsExpanded(!isExpanded);
            }}
            isExpanded={isExpanded}>
            <ArrowRight />
          </Styled.Toggle>
        )}
      </Styled.Container>

      {isExpanded &&
        children.map((child, i) => (
          <Styled.ChildLabel key={`${htmlKey}-child-${i}`} onClick={() => navigate(child.path)}>
            <Typography
              variant="caption"
              style={{ color: location.pathname === child.path ? theme.palette.primary.main : theme.palette.tertiary.main }}>
              {child.name}
            </Typography>
          </Styled.ChildLabel>
        ))}
    </Styled.Outer>
  );
};
