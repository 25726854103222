import { Children } from 'types/generic';

import * as Styled from './styles';

type ColumnsProps = {
  children: Children;
};

export const Columns: React.FC<ColumnsProps> = ({ children }) => {
  return <Styled.Container>{children}</Styled.Container>;
};
