import { Checkbox, FormControlLabel } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocationLaunchDate, SKU } from 'api/components/schemas';
import { AutocompleteProps, ChipSelector, Columns, FormElement, Loader, Modal } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { updateSku } from 'utils/apiRequests/skus';

import * as Styled from './styles';

interface AssignSkuModalProps {
  assignModal: { show: boolean; sku?: SKU };
  setAssignModal: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      sku?: SKU | undefined;
    }>
  >;
}

export const AssignSkuModal: React.FC<AssignSkuModalProps> = ({ assignModal, setAssignModal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [launchDate, setLaunchDate] = useState<Date | null>(null);
  const [delistDate, setDelistDate] = useState<Date | null>(null);
  const [limitedEdition, setLimitedEdition] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [dfExclusive, setDfExclusive] = useState<boolean>(false);
  const [selectedMarkets, setSelectedMarkets] = useState<AutocompleteProps[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<AutocompleteProps[]>([]);
  const { markets, locations } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const onConfirm = async () => {
    if (selectedMarkets.length > 0 && selectedLocations.length > 0 && launchDate && delistDate) {
      const locationLaunchDates: LocationLaunchDate[] = [];

      selectedMarkets.forEach((mrk) => {
        const locationsForThisMarket = locations?.filter((lc) => lc.market?._id === mrk.id);

        const selectedLocationsForThisMarket = selectedLocations.filter((sl) => locationsForThisMarket?.find((lc) => lc._id === sl.id));

        console.log('selectedLocationsForThisMarket:', selectedLocationsForThisMarket);

        selectedLocationsForThisMarket.forEach((sl) => {
          locationLaunchDates.push({
            locationId: sl.id,
            launchDate: launchDate,
            endDate: delistDate,
            limitedEdition: limitedEdition,
            new: isNew,
            dfExclusive: dfExclusive,
            marketId: mrk.id,
          });
        });
      });

      const updatedSku = { ...(assignModal.sku as SKU), locationLaunchDates };

      setIsLoading(true);

      const resp = await updateSku(updatedSku);
      if (resp) {
        enqueueSnackbar('Sku updated successfully', { variant: 'success' });
        setAssignModal({ show: false });
      } else {
        enqueueSnackbar('Error updating sku', { variant: 'error' });
      }

      setIsLoading(false);

      console.log('locationLaunchDates:', locationLaunchDates);
    } else {
      enqueueSnackbar('Please fill out all fields', { variant: 'error' });
    }
  };

  return (
    <Modal
      isOpen={assignModal.show}
      title={`Assign ${assignModal.sku?.skuName} to locations.`}
      maxWidth={'lg'}
      content={
        <>
          {isLoading && <Loader />}
          <ChipSelector
            id="market"
            label="Market"
            options={markets?.map((mrk) => ({ id: mrk._id, name: mrk.name })) ?? []}
            onChange={(val) => {
              console.log('val:', val);
              setSelectedMarkets(val);
            }}
            chips={[{ label: 'South Korea' }, { label: 'Japan' }]}
            value={selectedMarkets}
          />

          <ChipSelector
            id="locations"
            label="Locations"
            options={
              locations?.filter((lc) => selectedMarkets.find((mrk) => mrk.id === lc.market?._id)).map((lc) => ({ id: lc._id, name: lc.name })) ?? []
            }
            onChange={(val) => setSelectedLocations(val ?? null)}
            disabled={!selectedMarkets}
            chips={[{ label: 'Osaka' }, { label: 'Haneda' }, { label: 'Chubu' }, { label: 'Narita' }]}
            value={selectedLocations}
          />

          <Columns>
            <FormElement
              type="datePicker"
              label="Launch Date"
              required
              onChange={(val) => {
                setLaunchDate(new Date(val));
              }}
              value={new AdapterDayjs().date(launchDate)}
            />
            <FormElement
              type="datePicker"
              label="Delist Date"
              required
              onChange={(val) => setDelistDate(new Date(val))}
              value={new AdapterDayjs().date(delistDate)}
            />
          </Columns>

          <Styled.Checkboxes>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              onChange={(_, checked) => setIsNew(checked)}
              labelPlacement="top"
              label="New"
              value={isNew}
            />
            <FormControlLabel
              control={<Checkbox />}
              labelPlacement="top"
              label="Limited Edition"
              value={limitedEdition}
              onChange={(_, checked) => setLimitedEdition(checked)}
            />
            <FormControlLabel
              control={<Checkbox />}
              labelPlacement="top"
              label="DF Exclusive"
              value={dfExclusive}
              onChange={(_, checked) => setDfExclusive(checked)}
            />
          </Styled.Checkboxes>
        </>
      }
      actions={[
        {
          type: 'button',
          variant: 'contained',
          label: 'Continue',
          onClick: onConfirm,
        },
        {
          type: 'button',
          variant: 'outlined',
          label: 'Cancel',
          onClick: () => setAssignModal({ show: false }),
        },
      ]}
      onClose={() => setAssignModal({ show: false })}
    />
  );
};
