import { Breadcrumbs, Button, ButtonOwnProps, FormControlLabel, FormGroup, InputAdornment, Link, Switch, Typography } from '@mui/material';
import { FormElement, Loader } from 'components';
import { useNavigate } from 'react-router-dom';

import * as Styled from './styles';

export type ActionsProps = {
  breadcrumbs?: Array<{ label: string; path?: string }>;
  ctas?: Array<{
    type: string;
    label: string;
    Icon?: React.ComponentType;
    isChecked?: boolean;
    handleToggleArchived?: () => void;
    variant?: ButtonOwnProps['variant'];
    onClick: () => void;
    color?: string;
    isheader?: string;
    disabled?: boolean;
    isLoading?: boolean;
  }>;
};

export const Actions: React.FC<ActionsProps> = ({ breadcrumbs = [], ctas = [] }) => {
  const navigate = useNavigate();

  return (
    <Styled.Container>
      {breadcrumbs.length > 0 && (
        <Breadcrumbs>
          <Link underline="hover" color="inherit" onClick={() => navigate('/')}>
            PIM
          </Link>
          {breadcrumbs.map((crumb, count) => {
            if (count + 1 < breadcrumbs.length) {
              return (
                <Link
                  key={`breadcrumb-${count}`}
                  underline="hover"
                  color="inherit"
                  onClick={() => navigate(crumb.path as string)}
                  sx={{ cursor: 'pointer' }}>
                  {crumb.label}
                </Link>
              );
            }

            return <Typography key={`breadcrumb-${count}`}>{crumb.label}</Typography>;
          })}
        </Breadcrumbs>
      )}

      <Styled.Ctas>
        {ctas.map(
          (
            { type, label, Icon, isChecked, handleToggleArchived, onClick, disabled = false, isLoading = false, variant = 'text', ...rest },
            count,
          ) => {
            if (type === 'button') {
              return (
                <Button disabled={disabled} onClick={onClick} variant={variant} key={`action-${count}`} startIcon={Icon && <Icon />}>
                  {isLoading ? <Loader /> : label}
                </Button>
              );
            } else if (type === 'input') {
              return (
                <FormElement
                  size="small"
                  label={label}
                  {...rest}
                  key={`action-${count}`}
                  sx={{ width: '25ch' }}
                  InputProps={
                    Icon && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon />
                        </InputAdornment>
                      ),
                    }
                  }
                  onChange={() => {}}
                  value=""
                />
              );
            } else if (type === 'toggle') {
              return (
                <FormGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FormControlLabel control={<Switch checked={isChecked} onChange={handleToggleArchived} name="gilad" />} label={label} />
                </FormGroup>
              );
            } else {
              return <div />;
            }
          },
        )}
      </Styled.Ctas>
    </Styled.Container>
  );
};
