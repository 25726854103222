import { Alert, AlertColor, Typography } from '@mui/material';

import theme from 'config/theme';

import * as Styled from './styles';

type NotificationsProps = {
  severity: AlertColor;
  message: string | JSX.Element;
  extra: number;
};

export const Notifications: React.FC<NotificationsProps> = ({ severity, message, extra = undefined }) => {
  return (
    <Styled.Container hasExtra={extra !== undefined}>
      <Alert severity={severity} sx={{ margin: `${theme.spacing(2)} 0` }}>
        {message}
      </Alert>
      {extra !== undefined && (
        <Typography variant="body2" sx={{ color: theme.palette[severity].main }}>
          +{extra} Alerts
        </Typography>
      )}
    </Styled.Container>
  );
};
