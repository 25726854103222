import AccountCircle from '@mui/icons-material/AccountCircle';
import Close from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Link, Typography } from '@mui/material';
import { ShowMenuContext } from 'App';
import { Menu, MenuItem } from 'components';
import { useAuth } from 'context';
import { useContext } from 'react';
import { Children, ShowMenuContextProps } from 'types/generic';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

import * as Styled from './styles';

type LayoutProps = {
  children: Children;
  notAuthenticated?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({ children, notAuthenticated }) => {
  const showFullMenu = useContext(ShowMenuContext) as ShowMenuContextProps;

  const { logout } = useAuth();

  return (
    <div>
      <Styled.Header menuExpanded={showFullMenu.value}>
        <MenuItem Icon={showFullMenu.value ? Close : MenuIcon} isHeader onClick={() => showFullMenu.set(!showFullMenu.value)} />

        <Styled.LogoContainer>
          <Logo color="#fff" />
        </Styled.LogoContainer>

        <MenuItem Icon={AccountCircle} isHeader onClick={logout} />
      </Styled.Header>

      {!notAuthenticated && (
        <Styled.Menu menuExpanded={showFullMenu.value}>
          <div>
            <Menu isExpanded={showFullMenu.value} />
          </div>

          {/* <Styled.Like>
          <MenuItem Icon={ThumbUpOffAltIcon} label="Like" showLabel={showFullMenu.value} />
        </Styled.Like> */}
        </Styled.Menu>
      )}

      <Styled.Main menuExpanded={showFullMenu.value}>{children}</Styled.Main>

      <Styled.Footer>
        <Typography>
          v2.0.0&nbsp;|&nbsp;
          <Link href="https://www.pmiprivacy.com/global/en/" target="_blank" rel="noopener">
            Privacy policy
          </Link>
          &nbsp;|&nbsp;
          <Link>Cookie preferences</Link>
        </Typography>
      </Styled.Footer>
    </div>
  );
};
