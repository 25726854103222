import { AttributeValue, FlavourInstance } from 'api/components/schemas';
import axios, { AxiosRequestConfig } from 'axios';
import { EnqueueSnackbar } from 'notistack';
import { getAccessToken, handleApiError } from 'utils/helpers';

export const createFlavourInstance = async (marketId: string, enqueueSnackbar: EnqueueSnackbar) => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/flavour_instance`, { marketId }, { headers: { 'api-key': accessToken } });

    const newFlavourInstanceId = resp.data as string;

    return newFlavourInstanceId;
  } catch (err: any) {
    console.log(err);
    enqueueSnackbar(err?.response?.data?.error || 'Something went wrong', { variant: 'error' });
    handleApiError(err);
    return null;
  }
};

export const getFlavourInstance = async (flavourInstanceId: string) => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/flavour_instance`, {
      headers: { 'api-key': accessToken },
      params: { flavourInstanceId },
    });

    const flavourInstance = resp.data as FlavourInstance;

    return flavourInstance;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
export const getAllFlavourInstances = async (flavourInstanceIds?: string[]) => {
  try {
    const accessToken = await getAccessToken();

    const reqOptions: AxiosRequestConfig = { headers: { 'api-key': accessToken } };

    if (flavourInstanceIds && flavourInstanceIds.length > 0) {
      reqOptions.params = { flavourInstanceIds: flavourInstanceIds };
    }

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/flavour_instances`, reqOptions);

    const flavourInstance = resp.data as FlavourInstance[];

    return flavourInstance;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const getFlavourLanguages = async () => {
  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${process.env.REACT_APP_API_URL}/flavour_languages`, { headers: { 'api-key': accessToken } });

    const flavourLanguages = resp.data as AttributeValue[];

    return flavourLanguages;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};

export const updateFlavourInstances = async (flavourInstance: FlavourInstance, isDraft?: boolean) => {
  try {
    const accessToken = await getAccessToken();

    const reqOptions: AxiosRequestConfig = { headers: { 'api-key': accessToken } };

    if (isDraft) {
      reqOptions.params = { isDraft: true };
    }

    flavourInstance.lastUpdated = new Date();

    await axios.put(`${process.env.REACT_APP_API_URL}/flavour_instance`, { flavourInstance }, reqOptions);

    return true;
  } catch (err: any) {
    console.log(err);
    handleApiError(err);
    return null;
  }
};
