import styled from '@emotion/styled';

import theme from 'config/theme';

export const Add = styled.div`
  width: 60vw;
  margin-bottom: ${theme.spacing(4)};
`;

export const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: ${theme.spacing(2)};
`;

export const SKU = styled.div`
  width: 70%;
  display: flex;
  grid-column-gap: ${theme.spacing(2)};

  > * {
    white-space: nowrap;
  }
`;
