import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { ChangeEvent, useState } from 'react';

export type MenuTabLinkProps = {
  label: string;
  value: string;
};

type MenuTabsProps = {
  links: MenuTabLinkProps[];
  moreLinks?: MenuTabLinkProps[];
  value: string;
  activeMoreLinksView?: string;
  onChange: (ev: ChangeEvent, val: string) => void;
  callback?: () => void;
  canDelete?: boolean;
  onDelete?: (item: MenuTabLinkProps) => void;
};

export const MenuTabs: React.FC<MenuTabsProps> = ({
  links = [],
  moreLinks = [],
  value,
  onChange,
  activeMoreLinksView,
  callback = undefined,
  canDelete = false,
  onDelete = () => {},
}) => {
  const [showMoreAnchor, setShowMoreAnchor] = useState(null);
  const showMore = Boolean(showMoreAnchor);

  const openShowMore = (event: any) => {
    setShowMoreAnchor(event.currentTarget);
  };

  const closeShowMore = (event: any, link: string) => {
    onChange(event, link);
    setShowMoreAnchor(null);
  };

  const changeTab = (e: any) => {
    if (links.filter((link) => link.label === (e.target as HTMLElement)?.innerText)[0]) {
      onChange(e, links.filter((link) => link.label === (e.target as HTMLElement).innerText)[0].value);
    }
  };

  return (
    <>
      <Tabs value={value} onClick={changeTab}>
        {links?.map((link, tabCount) => (
          <Tab
            key={`tab-link-${tabCount}`}
            label={link.label}
            value={link.value}
            iconPosition="start"
            icon={
              canDelete ? (
                <IconButton onClick={() => onDelete(link)}>
                  <Delete />
                </IconButton>
              ) : undefined
            }
          />
        ))}
        {moreLinks.length > 0 && (
          <IconButton size="small" sx={{ top: '-10px' }} onClick={openShowMore}>
            <AddIcon />
          </IconButton>
        )}
      </Tabs>

      {moreLinks.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={showMoreAnchor}
          open={showMore}
          onClose={(e) => closeShowMore(e, 'more')}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {moreLinks.map((link, moreCount) => (
            <MenuItem key={`more-link-${moreCount}`} onClick={(e) => closeShowMore(e, link.value)} selected={activeMoreLinksView === link.value}>
              {link.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
