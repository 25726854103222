import { PublishedWithChangesOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const MCDataTable = ({ headings, data, selectable, showPagination = false, showArchived, link, instanceEditAction, handlePublish }) => {
  const [viewPage, setViewPage] = useState(0);
  const [viewNum, setViewNum] = useState(10);
  const navigate = useNavigate();

  const changePage = (_, page) => {
    setViewPage(page);
  };

  const changeNum = (ev) => {
    setViewNum(ev.target.value);
    setViewPage(0);
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell>
                  <Checkbox />
                </TableCell>
              )}
              {headings?.map((heading, countCol) => (
                <TableCell key={`col-${countCol}`}>{heading}</TableCell>
              ))}
              {link && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.slice(showPagination ? (viewPage * viewNum, viewPage * viewNum + viewNum) : 0).map((row, countRow) => {
              if (!showArchived && row.find((cell) => cell === 'Archived')) {
                return null;
              }
              return (
                <TableRow key={`row-${countRow}`}>
                  {selectable && (
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                  )}
                  {headings?.map((_, countCell) => (
                    <TableCell key={`row-${countRow}-cell-${countCell}`}>{row[countCell]}</TableCell>
                  ))}
                  {link && (
                    <TableCell>
                      <EditIcon
                        color="tertiary"
                        fontSize="small"
                        onClick={() => (instanceEditAction ? instanceEditAction(row, countRow) : navigate(link))}
                        sx={{ cursor: 'pointer', marginRight: '20px' }}
                      />
                      {handlePublish && row.find((cell) => cell === 'Draft' || cell === 'Archived') && (
                        <PublishedWithChangesOutlined
                          color="tertiary"
                          fontSize="small"
                          onClick={() => {
                            handlePublish(countRow);
                          }}
                          sx={{ cursor: 'pointer' }}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {showPagination && (
        <TablePagination
          component="div"
          count={data.length}
          page={viewPage}
          rowsPerPage={viewNum}
          onPageChange={changePage}
          onRowsPerPageChange={changeNum}
        />
      )}
    </div>
  );
};
