import { Alert, Box, Button, Card, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Layout } from 'components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the login logic here

    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_HUB_API_URL}/auth/password/reset/request`, {
        email,
      });

      const respData = resp.data;

      if (respData.emailSent) {
        alert('Email sent');
      }

      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout notAuthenticated={true}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Card sx={{ padding: '40px' }}>
            <Typography component="h1" variant="h5">
              Reset your password
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
              Enter your email address and if your account exists we will send you a email to reset your password. When you click on the link you will
              be taken to pmidfhub.com to reset your password. Once you have successfully reset your password you can then log in again with the new
              password here
            </Typography>

            {error && (
              <Alert onClose={() => setError(false)} severity="error">
                Something went wrong
              </Alert>
            )}

            <Box component="form" sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                {isLoading ? <CircularProgress size={25} color="secondary" /> : 'LOGIN'}
              </Button>
              <Typography>
                Did you remember your password?{' '}
                <Button
                  disabled={isLoading}
                  color="primary"
                  sx={{ paddingBottom: 0.2 }}
                  onClick={() => {
                    navigate('/login');
                  }}>
                  Log in here
                </Button>
              </Typography>
            </Box>
          </Card>
        </Box>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
