import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'types/generic';
import { reomveUserInLocalStorage, setUserInLocalStorage } from 'utils/helpers';

interface AuthContextProps {
  user: User | null;
  logout: () => void;
  login: (userData: User) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  logout: () => {},
  login: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const navigate = useNavigate();

  const login = (userData: User) => {
    setUserInLocalStorage(userData);
    setUser(userData);
    navigate('/portfolio');
  };
  const logout = () => {
    setUser(null);
    reomveUserInLocalStorage();
    navigate('/login');
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      login(JSON.parse(user));
    } else {
      navigate('/login');
    }
  }, []);

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
