import styled from '@emotion/styled';

import theme from 'config/theme';

import { BoxPadding } from '../Box/styles';

export const Head = styled.div`
  padding: ${BoxPadding};
  display: flex;
`;

export const HeadContent = styled.div`
  width: calc(100% - 40px);
`;

export const Toggle = styled.div<{ isOpen: boolean }>`
  width: 40px;
  transform: ${(props) => (props.isOpen === true ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all linear 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<{ isOpen: boolean }>`
  border-top: ${(props) => (props.isOpen === true ? `1px solid ${theme.palette.tertiary.dark}` : `0px solid ${theme.palette.tertiary.dark}`)};
  padding: ${(props) => (props.isOpen === true ? BoxPadding : 0)};
  height: ${(props) => (props.isOpen === true ? 'auto' : 0)};
  overflow: hidden;
  transition: all linear 0.2s;
`;
