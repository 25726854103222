import { Actions, AutocompleteProps, Box, FormElement, Layout } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFlavourInstance } from 'utils/apiRequests/flavours';

import * as Styled from './styles';

const AddDeployment = () => {
  const [selectedMarket, setSelectedMarket] = useState<AutocompleteProps | null>(null);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { markets } = useAppContext();

  const onConfirm = async () => {
    if (!selectedMarket?.id) return;
    const flavourInstanceId = await createFlavourInstance(selectedMarket.id, enqueueSnackbar);

    if (flavourInstanceId) {
      navigate('/digital-tools/flavor-navigator/add-deployment/skus', { state: { flavourInstanceIds: [flavourInstanceId] } });
    }
  };

  return (
    <Layout>
      <Actions
        breadcrumbs={[
          { label: 'Digital Tools', path: '/digital-tools' },
          { label: 'Flavour Navigator', path: '/digital-tools/flavor-navigator' },
          { label: 'Add Instance' },
        ]}
      />

      <Styled.Container>
        <Box title="Add instance">
          <FormElement
            options={markets?.map((mark) => ({ id: mark._id, name: mark.name }))}
            type="autocomplete"
            onAutocompleteChange={(val) => setSelectedMarket(val)}
            id="market"
            label="Market"
            onChange={() => {}}
            required
            value=""
          />
          {/* <ChipSelector
            id="locations"
            label="Location(s)"
            chips={[{ label: 'Osaka' }, { label: 'Haneda' }, { label: 'Chubu' }, { label: 'Narita' }]}
          /> */}
        </Box>

        <Actions
          ctas={[
            {
              type: 'button',
              variant: 'contained',
              label: 'Confirm',
              disabled: !selectedMarket,
              onClick: onConfirm,
            },
            {
              type: 'button',
              variant: 'outlined',
              label: 'Cancel',
              onClick: () => navigate('/digital-tools/flavor-navigator'),
            },
          ]}
        />
      </Styled.Container>
    </Layout>
  );
};

export default AddDeployment;
