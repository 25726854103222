import { Delete } from '@mui/icons-material';
import { IconButton, InputLabel } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';

import * as Styled from './styles';

type FiledropType = {
  label?: string;
  prompt?: string;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  acceptedFiles?: string[];
};

export const Filedrop: React.FC<FiledropType> = ({ file, setFile, prompt = 'Drag and drop an image file here or click to', acceptedFiles }) => {
  const onChange = (filesData: File[]) => {
    setFile(filesData[0]);
  };

  return (
    <Styled.Container>
      {file && <InputLabel shrink={true}>{file.name}</InputLabel>}
      <DropzoneArea
        showPreviews={false}
        showFileNamesInPreview={false}
        onChange={onChange}
        filesLimit={1}
        acceptedFiles={acceptedFiles}
        dropzoneText={prompt}
        fileObjects={[file]}
      />
      {file && (
        <div className="preview-overlay">
          <IconButton onClick={() => setFile(null)} className="delete-icon">
            <Delete />
          </IconButton>
          <img src={URL.createObjectURL(file)} alt="preview" />
        </div>
      )}
    </Styled.Container>
  );
};
