import styled from '@emotion/styled';

import theme from 'config/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)} 0;
  height: 51px;
  &:first-child {
    padding-top: 0;
  }
`;

export const Ctas = styled.div`
  display: flex;
  grid-column-gap: ${theme.spacing(2)};
`;
