import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Location, SKU } from 'api/components/schemas';
import { Actions, DataTable, Notifications } from 'components';
import { useAppContext } from 'context/AppContext';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSkuAlerts, getSkus } from 'utils/apiRequests/skus';
import { formatDate } from 'utils/helpers';

import { AssignSkuModal } from 'components/AssignSkuModal';

interface TableData {
  market: string;
  name: string;
  tradeChannel: string;
  brands: string;
  noSkus: string;
  id: string;
}

const Availablity = () => {
  const [skuAlerts, setSkuAlerts] = useState<SKU[]>([]);
  const [allSkus, setAllSkus] = useState<SKU[]>([]);
  const [tableData, setTableData] = useState<TableData[] | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [assignModal, setAssignModal] = useState<{ show: boolean; sku?: SKU }>({ show: false });
  const navigate = useNavigate();

  const { locations, designMarkets } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();

  const getSkuAlertsHelper = async () => {
    const skuAlerts = await getSkuAlerts();
    const skusResp = await getSkus();
    if (skuAlerts) {
      setSkuAlerts(skuAlerts);
    }

    if (skusResp) {
      setAllSkus(skusResp);
    }
  };

  useEffect(() => {
    if (locations) {
      const formattedData = formatData(locations);
      setTableData(formattedData);
      getSkuAlertsHelper();
    }
  }, [locations]);

  const formatData = (locationsData: Location[]): TableData[] => {
    return locationsData.map((loc) => {
      return {
        _id: loc._id,
        id: loc._id,
        market: loc?.market?.name,
        name: loc?.name,
        tradeChannel: loc?.tradeChannel?.name,
        brands: '',
        noSkus: '',
      };
    });
  };

  const onEditSelectedClick = () => {
    if (!selectedLocations) {
      enqueueSnackbar('Please select at least one location', { variant: 'error' });
    }

    const fullSelectedLocations = locations?.filter((loc) => selectedLocations?.find((slId) => slId === loc._id));

    // check that the market for every selected location is the same
    const marketIds = fullSelectedLocations?.map((loc) => loc.market._id);
    const uniqueMarketIds = [...new Set(marketIds)];
    console.log('uniqueMarketIds:', uniqueMarketIds);
    if (uniqueMarketIds.length > 1) {
      enqueueSnackbar('Please select locations from the same market', { variant: 'error' });
      return;
    }

    if (fullSelectedLocations) {
      navigate('/portfolio/edit-sku', { state: { selectedLocations: fullSelectedLocations } });
    }
  };

  return (
    <>
      <Actions
        breadcrumbs={[{ label: 'Availability' }]}
        ctas={[
          {
            type: 'button',
            variant: 'contained',
            label: 'Edit selected',
            Icon: EditIcon,
            onClick: onEditSelectedClick,
          },
          {
            type: 'input',
            variant: 'outlined',
            label: 'Search',
            Icon: SearchIcon,
            isheader: 'true',
            onClick: () => {},
          },
        ]}
      />

      {skuAlerts && skuAlerts.length > 0 && (
        <Notifications
          severity="warning"
          message={
            <>
              `{skuAlerts[0].skuName}` has been created in Design market `
              {designMarkets?.find((dm) => dm._id === skuAlerts[0].designMarketId)?.name ?? ''}` and is due to go live on `
              {formatDate(skuAlerts[0].globalLaunchDate)}`. Please assign this SKU to market(s){' '}
              <span onClick={() => setAssignModal({ show: true, sku: skuAlerts[0] })}>here</span>
            </>
          }
          extra={skuAlerts.length - 1}
        />
      )}

      {tableData && (
        <DataTable
          height="75vh"
          columns={[
            { field: 'market', headerName: 'Market', width: 300 },
            { field: 'name', headerName: 'Location', width: 350 },
            { field: 'tradeChannel', headerName: 'Trade Channel', width: 250 },
            {
              field: 'brands',
              headerName: 'Brands',
              width: 200,
              valueGetter: (params) => {
                //find the sku where the location id is equal to the location id in the table
                const locationSkus = allSkus.filter((sku) => sku.locationLaunchDates.find((loc) => loc.locationId === params.row.id));

                if (locationSkus) {
                  // calculate the number of unique brands
                  const brands = locationSkus.reduce((acc, curr) => {
                    if (!acc.includes(curr.brandFamilyId)) {
                      acc.push(curr.brandFamilyId);
                    }
                    return acc;
                  }, [] as string[]);
                  return brands.length;
                } else {
                  return '0';
                }
              },
            },
            {
              field: 'noSkus',
              headerName: 'No.SKUs',
              width: 200,
              valueGetter: (params) => {
                //find the sku where the location id is equal to the location id in the table
                const locationSkus = allSkus.filter((sku) => sku.locationLaunchDates.find((loc) => loc.locationId === params.row.id));

                if (locationSkus) {
                  return locationSkus.length;
                } else {
                  return '0';
                }
              },
            },
          ]}
          rows={tableData}
          selectable
          selectedRows={selectedLocations}
          setSelectedRows={setSelectedLocations}
        />
      )}

      <AssignSkuModal assignModal={assignModal} setAssignModal={setAssignModal} />
    </>
  );
};

export default Availablity;
