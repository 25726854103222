import { Alert, Box, Button, Card, CircularProgress, Container, CssBaseline, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { Layout } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle the login logic here

    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_HUB_API_URL}/auth/login`, { email, password });

      const data = resp.data;

      if (data.twoFactorChallenge) {
        navigate('/two-factor', { state: { email, password, userId: resp.data.userId as string } });
      }

      console.info(data);
      setIsLoading(false);
    } catch (err: any) {
      console.log(err);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <Layout notAuthenticated={true}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Card sx={{ padding: '40px' }}>
            <Typography component="h1" variant="h5">
              Login User
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
              Login with your Play Monitoring Account
            </Typography>

            {error && (
              <Alert onClose={() => setError(false)} severity="error">
                Incorrect Username or Password
              </Alert>
            )}
            <Box component="form" sx={{ width: '100%', mt: 3 }} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                type="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                {isLoading ? <CircularProgress size={25} color="secondary" /> : 'LOGIN'}
              </Button>
            </Box>

            <Typography>
              Did you forget your password?{' '}
              <Button
                disabled={isLoading}
                color="primary"
                sx={{ paddingBottom: 0.2 }}
                onClick={() => {
                  navigate('/forgot-password');
                }}>
                Reset password here
              </Button>
            </Typography>
          </Card>
        </Box>
      </Container>
    </Layout>
  );
};

export default Login;
