import axios from 'axios';
import { User } from 'types/generic';
import { getRefreshToken, getUserFromLocalStorage, setUserInLocalStorage } from 'utils/helpers';

export const refreshToken = async () => {
  try {
    const user = getUserFromLocalStorage();
    const refreshToken = getRefreshToken();
    const resp = await axios.post(
      `${process.env.REACT_APP_HUB_API_URL}/auth/refresh`,
      { userId: user?.userId, email: user?.email },
      { headers: { Authorization: `Bearer ${refreshToken}` } },
    );
    if (resp.data) {
      setUserInLocalStorage(resp.data as User);
      return true;
    } else {
      throw new Error('No user data in refresh token response');
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
